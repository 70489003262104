<template lang="pug">
b-modal#modal-catalog-missions(ref='modal-catalog-missions' size='xl' centered='centered' :title='"Calatogue des tâches courantes"' @ok='create' @cancel="closeModal")
  form#form-element
    .content-grid.d-flex.w-100
      .text-center.flex-center.w-100(v-if='isLoadingMissionsList || !headerLoaded || isLoadingAnalyticsSettingsList')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
            br
            br
            br
            | Chargement de la liste des tâches..
      sync-tree-grid#overviewtreegrid(ref="overviewtreegrid" v-else :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @this.$refs.overviewtreegrid="this.$refs.overviewtreegrid" @actionBegin="actionBegin")
  template(#modal-footer='{ ok, cancel }')
    .w-100.mx-0
      b-button.float-right(variant='primary' @click='ok()' v-if="selectedRows.length>0") Ajouter la sélection
      b-button.float-right(variant='outline-primary' :class="selectedRows.length>0 ? 'mr-1' : ''" @click='cancel()') Annuler

</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncTreeGrid,
    HeaderGrid,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      name: "mission",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],

      dateRanger: false,
      tvaModeButton: false,
      addButton: false,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher une tâche",
      allowGrouping: false,
      parentIdMapping: "parentId",
      idMapping: "id",
      hasChildMapping: "parentId",
      treeColumnIndex: 1,
    };
  },
  computed: {
    ...mapGetters([
      "missionsList",
      "isLoadingMissionsList",
      "institutionSelected",
      "workspaceSelected",
      "unitsList",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
    ]),
    dataFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
  },
  async created() {
    this.selectedRows = [];
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({});
    }
    if (!this.analyticsSettingsList || this.analyticsSettingsList.length == 0) {
      await this.getAnalyticsSettings({});
    }
    await this.getMissions().then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions(["getMissions", "getUnits", "getAnalyticsSettings"]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: true,
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          validationRules: { required: true, minLength: 2 },
          clipMode: "EllipsisWithTooltip",
        },
        {
          type: "number",
          field: "analyticsSettingId",
          headerText: "Code analytique lié",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "displayLabel",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "estimatedQuantity",
          headerText: "Qté par défaut",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          editType: "numericedit",
          textAlign: "Center",
          edit: { params: { decimals: 2 } },
        },
        {
          type: "number",
          field: "unitId",
          filterTemplate: "untiFilterTemplate",
          headerText: "Unité par défaut",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          edit: {
            source: this.unitsList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
          // type : "dropdown" //<<FIX
        },
        {
          type: "number",
          field: "estimatedUnitPrice",
          headerText: "Prix U. par défaut",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          editType: "numericedit",
          textAlign: "Center",
          edit: { params: { decimals: 2 } },
        },
      ];
    },
    setSelectedRows(rows) {
      this.selectedRows = rows;
    },
    async actionBegin(args) {},
    popupCatalogMissionsEvent() {
      this.getMissions({});
    },
    closeModal() {
      this.$bvModal.hide("modal-catalog-missions");
    },
    async create(bvModalEvt) {
      bvModalEvt.preventDefault();

      this.$nextTick(() => {
        this.$emit("closePopupCatalogMission", this.selectedRows);
        this.closeModal(this.selectedRows);
      });
    },
  },
};
</script>
<style>
#modal-catalog-missions .modal-body {
  padding: 0px !important;
}
</style>
