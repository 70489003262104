<template lang="pug">
  b-modal(
    id="edit-planning-view-modal"
    ref="modalEditPlanningView"
    :visible.sync="isVisible"
    size="xl"
    title="Modifier la vue du planning"
    class="modal-content"
    @ok="create"
    @hide="onModalHide"
  )
    .planningView.d-flex.flex-column.h-100
      .d-flex(class="h-100 flex-grow-1")
        .d-flex.flex-column.pr-1.w-50.h-100(
          style="border-right: 1px solid #e2e2e2;"
        )
          ul.nav.nav-tabs-custom
            li.nav-item
                a.nav-link(:class="{ 'current': tabActive === 'general', 'active-tab': tabActive === 'general' }" @click="tabActive = 'general'")
                    | Général
            li.nav-item
                a.nav-link(:class="{ 'current': tabActive === 'columns', 'active-tab': tabActive === 'columns' }" @click="tabActive = 'columns'")
                    | Colonnes
            li.nav-item
                a.nav-link(:class="{ 'current': tabActive === 'collaborateurs', 'active-tab': tabActive === 'collaborateurs' }" @click="tabActive = 'collaborateurs'")
                    | Collaborateurs
            li.nav-item
                a.nav-link(:class="{ 'current': tabActive === 'affaires', 'active-tab': tabActive === 'affaires' }" @click="tabActive = 'affaires'")
                    | Affaires
            li.nav-item
                a.nav-link(:class="{ 'current': tabActive === 'lignesLibres', 'active-tab': tabActive === 'lignesLibres' }" @click="tabActive = 'lignesLibres'")
                    | Lignes libres
            b-form-input(
              v-show="tabActive !== 'general' && tabActive !== 'columns'"
              id="searchLines"
              v-model="searchLines"
              placeholder="Rechercher une ligne"
              class="mt-2"
              type="search"
            )
          validation-observer(ref="formCreatePlanningView")
            div.mt-2.h-100(v-show="tabActive === 'general'")
              b-form-group(label="Type de planning" label-for="type")
                validation-provider(#default="{ errors }" name="type")
                  v-select.my-1(id="type" :loading='isLoadingPlanningViewTypesList' :clearable='false' :hide-selected='true' :value='planningViewForm.type' :options='planningViewTypesList' :reduce="(elem) => elem.value" :on-change="changePlanningViewType")
                      template(v-slot:no-options='')
                        template  Aucun r&eacute;sultat trouv&eacute;
                      li.border-bottom.p-1.py-50(slot='list-header')
                        b-button.w-100.d-flex.justify-content-center.align-items-center(variant='primary' size='sm')
                          | + Nouveau type de planning
                          span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
              b-form-group(label="Nom du planning *" label-for="name")
                validation-provider(#default="{ errors }" name="name" rules="required")
                  b-form-input.my-1(id="name" v-model="planningViewForm.name" :state="errors.length > 0 ? false : null")
                  small.text-danger {{ errors[0] }}
              .d-flex.flex-column.w-100
                small.cursor-pointer.text-primary.my-1(@click="displayMore = !displayMore")
                    | {{ (displayMore) ? "- Masquer les informations supplémentaires" : "+ Afficher les informations supplémentaires" }}      
                .d-flex.w-100.my-1(v-if="displayMore")
                  .d-flex.w-100.flex-column.mb-1
                    b-form-group(label="Description" label-for="description")
                      vs-textarea(v-model="planningViewForm.description" height="140px" style="min-height: 140px; font-size: 1rem")
              .d-flex.flex-column.w-100
                .d-flex.flex-column
                  h5 Paramètres d'affichage
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isPublicHoliday")
                    | Différencier graphiquement les jours fériés
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isCurrentDay")
                    | Différencier graphiquement la date du jour
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isShowColGroup")
                    | Afficher la colonne groupe
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isAutoOrderedByGroup")
                    | Organiser automatiquement par groupe alphabétique. Attention, le rendu de ce mode n'est pas visible en mode création.
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isAutoOrderedByName")
                    | Organiser automatiquement par nom de ligne alphabétique
                .d-flex.flex-column.w-100.mt-2
                  h5 Paramètres de gestion
                  b-form-checkbox(checked="true" name="check-button" switch v-model="planningViewForm.isActiveSearch")
                    | Activer la recherche

          div.h-100.mt-2(v-show="tabActive === 'columns'" style="overflow-y: auto; max-height: 680px;")
            div.scrollable-content.h-100(style="")
              b-form-group(label="Colonnes à afficher" label-for="columns")
                table.table
                  thead
                    tr
                      th(scope="col") Affichée
                      th(scope="col") Nom de la colonne
                      th(scope="col") Description
                  tbody
                    tr(v-for="(column, index) in columns" :key="index")
                      td
                        b-form-checkbox(
                          v-model="selectedColumns"
                          :value="column.field"
                          switch
                          class="text-center"
                        )
                      td
                        | {{ column.text }}
                      td.text-muted
                        | {{ column.description }}

          div.h-100.mt-2(v-show="tabActive == 'collaborateurs'" style="max-height: 680px; overflow-y: auto;")
            .d-flex.flex-column.h-100
                .d-flex.flex-column.w-100
                  .tabsLines
                    .scrollable-content
                      draggable(group="planningLineView" class="list-group list-group-flush cursor-move" tag="ul" :list="planningLinesCollaboratorListMapped")
                        transition-group(type="transition" name="flip-list" class="flip-list")
                          b-list-group-item(
                            v-show="planningLine ? planningLine.name.toLowerCase().includes(searchLines.toLowerCase()) : ''"
                            tag="li"
                            v-for="(planningLine, indexCollaboratorList) in planningLinesCollaboratorListMapped"
                            :key="'planningLinesCollaboratorListMapped' + indexCollaboratorList"
                          )
                            .w-100.d-flex.justify-content-between.align-items-center
                              span.pl-50 {{ planningLine.name }}
                              .mr-1.d-flex.align-items-center(v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length")
                                span.font-medium-2.font-weight-bold.mr-1.text-primary(
                                  v-show="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1"
                                  :class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''"
                                )
                                  | {{ planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length }}
                                span.material-icons-outlined.text-primary(:class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''") done

          div.h-100.mt-2(v-show="tabActive == 'affaires'" style="overflow-y: auto; max-height: 680px;")
              draggable(group="planningLineView" class="list-group list-group-flush cursor-move" tag="ul" :list="planningLinesAffairListMapped")
                transition-group(type="transition" name="flip-list" class="flip-list")
                  b-list-group-item(
                    v-show="planningLine ? planningLine.name.toLowerCase().includes(searchLines.toLowerCase()) : ''"
                    tag="li"
                    v-for="(planningLine, indexAffairList) in planningLinesAffairListMapped"
                    :key="'planningLinesAffairList' + indexAffairList"
                  )
                    .w-100.d-flex.justify-content-between.align-items-center
                      span.pl-50 {{ planningLine.name }}
                      .mr-1.d-flex.align-items-center(v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length")
                        span.font-medium-2.font-weight-bold.mr-1.text-primary(
                          v-show="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1"
                          :class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''"
                        )
                          | {{ planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length }}
                        span.material-icons-outlined.text-primary(:class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''") done

          div.h-100.mt-2(v-show="tabActive == 'lignesLibres'" style="max-height: 680px; overflow-y: auto;")
              .d-flex.flex-column.h-100
                .d-flex.flex-column.w-100
                  .tabsLines
                    .scrollable-content
                      draggable(group="planningLineView" class="list-group list-group-flush cursor-move" tag="ul" :list="planningLinesFreeLineListMapped")
                        transition-group(type="transition" name="flip-list" class="flip-list")
                          b-list-group-item(
                            tag="li"
                            v-for="(planningLine, indexFreeList) in planningLinesFreeLineListMapped"
                            :key="'planningLinesFreeLineList' + indexFreeList"
                            v-show="planningLine ? planningLine.name.toLowerCase().includes(searchLines.toLowerCase()) : ''"
                          )
                            .w-100.d-flex.justify-content-between.align-items-center
                              span.d-flex.align-items-center
                                span.material-icons-outlined.pl-50.mr-1(@click="popupCreateFreeLine(planningLine.idLine)") edit
                                span.pl-50 {{ planningLine.name }}
                              .mr-1.d-flex.align-items-center(v-if="planningViewForm.linePositions && planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length")
                                span.font-medium-2.font-weight-bold.mr-1.text-primary(
                                  v-show="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1"
                                  :class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''"
                                )
                                  | {{ planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length }}
                                span.material-icons-outlined.text-primary(:class="planningViewForm.linePositions.filter((elem) => elem.planningLineId == planningLine.planningLineId).length > 1 ? 'text-warning' : ''") done
                small.cursor-pointer.text-primary.mt-1(@click="popupCreateFreeLine(0)") + Ajouter une ligne libre

        .d-flex.flex-column.w-50.p-1(class="render" style="background-color: #eef1f6; overflow-y: auto; max-height: 730px;")
          .content-header
            .d-flex.align-items-center.mb-1
              h3.m-0.mr-1 Aperçu
          .planningLines.flex-grow-1(:class="planningViewForm.linePositions && planningViewForm.linePositions.length > 0 ? '' : 'add-text-draggin'")
            .t-head.d-flex.justify-content-between(
              :style="planningViewForm.linePositions && planningViewForm.linePositions.length > 0 ? '' : 'display: none !important;'"
            )
              div(style="flex: 2; padding: 0.25rem") Nom
              div(style="flex: 0.8; padding: 0.25rem") Groupe
              div(style="width: 60px" class="text-center") Couleur
              div(style="width: 80px; margin-left: 40px" class="text-center") Action
            draggable(
              v-model="planningViewForm.linePositions"
              class="t-row list-group list-group-flush cursor-move"
              tag="div"
              group="planningLineView"
              @change="onChange"
              :list="planningViewForm.linePositions"
              :style="planningViewForm.linePositions && planningViewForm.linePositions.length > 0 ? 'display:contents' : ''"
            )
              transition-group(
                type="transition"
                name="flip-list"
                :style="planningViewForm.linePositions && planningViewForm.linePositions.length > 0 ? 'display:contents' : 'border: 2px dashed #e0e0e0;'"
              )
                .t-r.d-flex.justify-content-between.align-items-center(
                  v-for="(ligne, index) of planningViewForm.linePositions"
                  :key="'typeLine' + index"
                  :class="'typeLine_' + ligne.typeLine"
                  :style="ligne.color ? 'background:' + ligne.color + '40 !important' : ''"
                )
                  // Colonne Nom
                  div.d-flex.flex-column.test(style="flex: 2", :style="ligne.color ? 'background:' + ligne.color + '40 !important' : ''")
                    b-form-input(:id="'label-' + index" v-model="ligne.name" class="title")
                  // Colonne Groupe
                  div.d-flex.flex-column(style="flex: 0.8", :style="ligne.color ? 'background:' + ligne.color + '40 !important' : ''")
                    b-form-input(:id="'group-' + index" v-model="ligne.group" class="title")
                  // Colonne Couleur
                  div.d-flex.align-items-center.justify-content-center(style="width: 60px", :style="ligne.color ? 'background:' + ligne.color + '40 !important' : ''")
                    validation-provider(#default="{ errors }" name="Color")
                      ejs-colorpicker(id="color" v-model="ligne.color" mode="Palette")
                  // Colonne Action
                  div.d-flex.align-items-center.justify-content-end(style="width: 80px; margin-left: 40px", :style="ligne.color ? 'background:' + ligne.color + '40 !important' : ''")
                    span.handle.material-icons.cursor-pointer(:class="ligne.isLocked ? 'text-secondary' : 'text-light'" @click="lockedItem(index)" :title="ligne.isLocked ? 'Autoriser la planification sur cette ligne' : 'Interdire la planification sur cette ligne'") block
                    span.handle.material-icons.cursor-move drag_indicator
                    span.material-icons-outlined.cursor-pointer(@click="removeItem(index)") clear
          .d-flex
            small.cursor-pointer.text-primary(@click="popupCreateFreeLine(0, 3, true)") + Ajouter un titre
            small.pl-2.cursor-pointer.text-primary(@click="popupCreateFreeLine(0, 4, true)") + Ajouter un sous-titre

    template(#modal-footer="{ ok, cancel }")
      b-button(variant="secondary" @click="cancel")
        | Annuler
      b-button(variant="primary" @click="create")
        | Enregistrer

    popup-create-free-line(ref="popupCreateFreeLine" @returnLine="returnLine")
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import { debounce } from "lodash";
import vSelect from "vue-select";
import draggable from "vuedraggable";
import Ripple from "vue-ripple-directive";
import { ETypeLine } from "@/types/api-orisis/enums/enums";
import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";

import PopupCreateFreeLine from "@/components/planning/planning-settings/popup-planning-settings/PopupCreateFreeLine";

configure({
  // Generates an English message locale generator
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
      regex:
        "Doit contenir au moins : 1 majuscule, 1 minuscule, 1 chiffre, et 1 caractère spécial.",
    },
  }),
});
localize("fr");

import { mapGetters, mapActions } from "vuex";
import { BFormCheckbox, BFormSpinbutton } from "bootstrap-vue";
import { mask } from "vue-the-mask";

export default {
  props: {
    planningViewId: {
      type: Number,
      default: 0,
    },
    columns: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      displayMore: false,
      scrolled: false,
      required,
      url,
      email,
      dataOrigine: {},
      planningViewForm: {},
      searchLines: "",
      isVisible: false,
      tabActive: "general",
      selectedColumns: this.columns
        .filter((column) => !column.hidden)
        .map((column) => column.field),
    };
  },
  created() {
    this.getPlanningLineFreeLines({});
    this.getPlanningLineCollaborators({});
    this.getPlanningLineAffairs({});
    if (this.planningViewId > 0) {
      this.popupPlanningViewEvent(this.planningViewId);
    }
  },
  mounted() {
    window.addEventListener("scroll", this.onScroll, true);
    this.deteleAutocomplete();
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.onScroll, true);
  },
  watch: {
    selectedColumns(newVal) {
      this.$emit("update-columns", newVal);
    },
  },
  methods: {
    ...mapActions([
      "createPlanningView",
      "getPlanningViewById",
      "updatePlanningView",
      "archivePlanningViews",
      "getPlanningLineFreeLines",
      "getPlanningLineCollaborators",
      "getPlanningLineAffairs",
    ]),
    onScroll(e) {
      let mainNavLinks = document.querySelectorAll(
        ".content-scrollable-sticky-nav ul li a"
      );
      let fromTop = e.target.scrollTop;

      mainNavLinks.forEach((link) => {
        let section = document.querySelector(link.hash);
        if (
          section.offsetTop <= fromTop &&
          section.offsetTop + section.offsetHeight > fromTop
        ) {
          link.classList.add("current");
        } else {
          link.classList.remove("current");
        }
      });
    },
    setPlanningViewData(planningViewData) {
      this.planningViewForm = { ...planningViewData };
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    popupPlanningViewEvent(id) {
      if (id > 0) {
        this.getPlanningViewById({
          planningViewId: id,
        }).then((res) => {
          this.planningViewForm = res;
          this.planningViewForm.linePositions =
            this.planningViewForm.linePositions.map((elem) => {
              return {
                id: elem.id,
                position: elem.position,
                name: elem.name,
                group: elem.group,
                color: elem.color,
                isLocked: elem.isLocked,
                typeLine: elem.typeLine,
                planningLineId: elem.planningLineId,
              };
            });
          this.dataOrigine = JSON.stringify(this.planningViewForm);
        });
      } else {
        this.initializeForm();
      }
    },
    initializeForm() {
      this.planningViewForm = {
        id: 0,
        name: "",
        linePositions: [],
        description: null,
        isActiveSearch: false,
        isAutoOrderedByGroup: false,
        isAutoOrderedByName: false,
        isCurrentDay: false,
        isDefault: false,
        isPublicHoliday: false,
        isShowColGroup: false,
        order: 1,
        readOnly: false,
      };
      this.dataOrigine = JSON.stringify(this.planningViewForm);
    },
    create() {
      this.$refs.formCreatePlanningView
        .validate()
        .then((success1) => {
          if (success1) {
            if (this.planningViewForm.linePositions.length === 0) {
              this.planningViewForm.linePositions = null;
            }

            if (this.planningViewForm.id) {
              this.updatePlanningView({
                planningView: this.planningViewForm,
              }).then(() => {
                this.$emit("planning-view-updated", this.planningViewForm); // Émission de l'événement
                this.isVisible = false;
              });
            } else {
              this.createPlanningView({
                planningView: this.planningViewForm,
              }).then(() => {
                this.$emit("planning-view-updated", this.planningViewForm); // Émission de l'événement
                this.isVisible = false;
              });
            }
          } else {
            console.log("create: Validation failed.");
          }
        })
        .catch((err) => {});
    },
    onChange(evt) {
      for (
        let index = 0;
        index < this.planningViewForm.linePositions.length;
        index++
      ) {
        const element = this.planningViewForm.linePositions[index];
        element.position = index;
        delete element.idLine;
      }
    },
    lockedItem(index) {
      this.planningViewForm.linePositions[index].isLocked =
        !this.planningViewForm.linePositions[index].isLocked;
    },
    removeItem(index) {
      this.planningViewForm.linePositions.splice(index, 1);
    },
    returnLine(val) {
      this.planningViewForm.linePositions.push({
        id: 0,
        idLine: val.id,
        position: this.planningViewForm.linePositions
          ? this.planningViewForm.linePositions.length
          : 0,
        name: val.name,
        group: "",
        color: "#ffffff00",
        isLocked: false,
        typeLine: val.typeLine,
        planningLineId: val.id,
      });
    },
    // Controls from
    archivePlanningViewLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer ce planning en consultant la liste de vos archives.",
          {
            title:
              'Êtes-vous sûr de vouloir archiver le planning "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archivePlanningViews({
              planningViewIds: [id],
            });
            if (this.$route.params.routeOrigine) {
              this.$tabs.close({ to: "/" + this.$route.params.routeOrigine });
            } else {
              this.isVisible = false;
            }
          }
        });
    },
    cancel() {
      if (this.dataOrigine === JSON.stringify(this.planningViewForm)) {
        this.isVisible = false;
      } else {
        this.$bvModal
          .msgBoxConfirm("Cette action est définitive et irréversible.", {
            title:
              "Êtes-vous sûr de vouloir quitter l'édition sans enregistrer ?",
            size: "sm",
            okVariant: "primary",
            okTitle: "Oui",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          })
          .then((value) => {
            if (value) {
              this.isVisible = false;
            } else {
            }
          });
      }
    },
    onCancel() {
      this.cancel();
    },
    onModalHide(bvEvent) {
      this.isVisible = false;
    },
    showPlanning(planningViewForm) {
      this.$router.push({
        name: "planning",
        params: {
          id: planningViewForm.id,
          title: "Planning : " + planningViewForm.name,
          tips: "Planning : " + planningViewForm.name,
        },
      });
    },
    popupCreateFreeLine(id, type = 2, returnFreeLine = false) {
      this.$bvModal.show("modal-create-free-line");
      this.$refs["popupCreateFreeLine"].popupCreateFreeLineEvent(
        id,
        type,
        returnFreeLine
      );
    },
  },
  computed: {
    ...mapGetters([
      "isLoadingPlanningView",
      "planningViewsList",
      "planningLinesFreeLineList",
      "planningLinesCollaboratorList",
      "planningLinesAffairList",
      "isCreatingPlanningView",
      "isUpdatingPlanningView",
      "isArchivingPlanningView",
    ]),
    planningLinesCollaboratorListMapped() {
      return this.$store.getters.planningLinesCollaboratorList.map((elem) => {
        return {
          id: 0,
          position: this.planningViewForm.linePositions
            ? this.planningViewForm.linePositions.length
            : 0,
          name: elem.name,
          group: "",
          color: "#ffffff00",
          isLocked: false,
          typeLine: ETypeLine.CollaboratorLine,
          planningLineId: elem.id,
        };
      });
    },
    planningLinesAffairListMapped() {
      return this.$store.getters.planningLinesAffairList.map((elem) => {
        return {
          id: 0,
          position: this.planningViewForm.linePositions
            ? this.planningViewForm.linePositions.length
            : 0,
          name: elem.name,
          group: "",
          color: "#ffffff00",
          isLocked: false,
          typeLine: ETypeLine.AffairLine,
          planningLineId: elem.id,
        };
      });
    },
    planningLinesFreeLineListMapped() {
      return this.$store.getters.planningLinesFreeLineList.map((elem) => {
        return {
          id: 0,
          idLine: elem.id,
          position: this.planningViewForm.linePositions
            ? this.planningViewForm.linePositions.length
            : 0,
          name: elem.name,
          group: "",
          color: "#ffffff00",
          isLocked: false,
          typeLine: elem.typeLine,
          planningLineId: elem.id,
        };
      });
    },
  },
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormSpinbutton,
    FormWizard,
    TabContent,
    draggable,
    PopupCreateFreeLine,
  },
  directives: {
    Ripple,
    mask,
  },
};
</script>
<style>
#edit-planning-view-modal .modal-body {
  height: 100% !important;
}

#edit-planning-view-modal .modal-content {
  height: calc(100dvh - 100px) !important;
  width: calc(100dvw - 100px) !important;
}
</style>

<style scoped>
.nav-link {
  position: relative;
  padding-bottom: 4px;
}

.nav-link.active-tab::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background-color: #0c3571;
}
</style>
