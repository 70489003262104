<template lang="pug">
div.w-100.h-100
    HeaderGrid(:title="title" :uid="uid" :name="name" userFilter="affairCostingFilters" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :addButton2Icon="addButton2Icon" :searchButton="searchButton" :selectedRows="selectedRows" @setDates="setDates" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" :searchPlaceholder="searchPlaceholder" :groupActionButton="groupActionButton" :groupActionButtonLabel="groupActionButtonLabel" :indexSearch="indexSearch" :homepageMode="homepageMode" :homepage="homepage")
    .content-grid.d-flex.w-100(style="height: calc(100% - 62px);")
        .text-center.flex-center.w-100(v-if='isLoadingReportDetailsList || !headerLoaded')
            .loading-bg-inner
                .loader
                    .outer
                    .middle
                    .inner
            .mt-5
                br
                br
                br
                | Chargement de la liste des reportings...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :allowGrouping="allowGrouping" :allowKeyboard="allowKeyboard")
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import { reportingStatus } from "@/types/api-orisis/enums/enums";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      homepageMode: false,
      homepage: false,
      indexSearch: 0,
      title: "Reporting",
      name: "affairReportDetails",
      uid: "affairReportDetails",
      statusList: reportingStatus.filter((el) => !el.notUse),
      headerData: [],
      headerLoaded: false,
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],

      dateRanger: true,
      tvaModeButton: false,
      addButton: false,
      addButton2: false,
      addButton2Icon: "e-icons e-people",
      searchButton: true,
      groupActionButton: true,
      groupActionButtonLabel: "Changer le statut",
      searchPlaceholder: "Rechercher une tâche/mission",
      allowKeyboard: false,
      allowGrouping: true,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      "reportDetailsList",
      "isLoadingReportDetailsList",
      "collaboratorsList",
      "missionsList",
      "workspaceSelected",
      "userParametersList",
    ]),
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataFiltered() {
      return this.$store.getters["reportDetailsList"].map((el) => {
        return {
          ...el,
          collaboratorFullName: el.collaboratorId
            ? `${
                this.collaboratorsList.find(
                  (collab) => collab.id === el.collaboratorId
                ).firstName
              } ${
                this.collaboratorsList.find(
                  (collab) => collab.id === el.collaboratorId
                ).lastName
              }`
            : "Non affecté",
        };
      });
    },
  },
  async created() {
    if (!this.missionsList || this.missionsList.length == 0) {
      await this.getMissions({});
    }
    this.getCollaborators({});
    this.headerLoaded = false;
    let filter = this.userParametersList.find(
      (el) => el.key == "affairCostingFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions(["getReportDetails", "getMissions", "getCollaborators"]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "date",
          format: "formatDateOptions",
          field: "reportDate",
          headerText: "Date",
          width: 95,
          maxWidth: 95,
          template: "dateYmdTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: false,
          showInColumnChooser: true,
          visible: true,
        },
        {
          type: "string",
          field: "collaboratorFullName",
          headerText: "Collaborateur",
          width: 200,
          minWidth: 140,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: false,
        },
        {
          type: "number",
          field: "missionId",
          filterTemplate: "missionFilterTemplate",
          headerText: "Tâche",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.missionsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.missionsListFiltered,
            },
            type: "dropdowndependencies",
            name: "dropdownmission",
            origine: "dropdownaffair",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "duration",
          headerText: "Durée réelle",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2, min: 0, max: 24, step: 0.25 } },
          validationRules: { required: true },
          allowAggregate: true,
        },
        {
          type: "number",
          format: "formatNumber",
          field: "durationValue",
          headerText: "Durée valorisée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          editType: "numericedit",
          defaultValue: 1,
          edit: { params: { decimals: 2, min: 0, max: 24, step: 0.25 } },
          validationRules: { required: true },
          allowAggregate: true,
        },
        {
          type: "string",
          field: "message",
          headerText: "Commentaire",
          width: "auto",
          minWidth: 300,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          showFooterSum: false,
          clipMode: "EllipsisWithTooltip",
          template: "stripHtmlTemplate",
          filter: { type: "Menu", operator: "contains" },
          edit: { type: "textarea", name: "message" },
        },
      ];
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getReportDetails({
          affairId: this.affairId,
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media screen and (max-width: 575px) {
  .content-grid {
    padding-top: 1rem;
    height: calc(100% - 92px) !important;
  }
}
</style>
