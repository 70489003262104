<template lang="pug">
div.px-1.w-100
    .d-flex.pt-1.w-100
        .d-flex.flex-column.w-100
            b-form-group(label-for="affairCompany"  label="Client lié *")
                ejs-dropdownlist(id='affairCompany' v-if="companiesLoaded" placeholder='Selectionnez un client' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='companiesList' :fields="{ text: 'name', value: 'id' }" v-model='affairForm.companyId')
                    template(v-slot:footerTemplate)
                        div.p-50
                            ejs-button(v-on:click.native="newCompany()" :content="'+ Nouveau client'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
    .d-flex.mt-1.w-100.justify-content-between.inputs-wrapper
        .d-flex.flex-column.w-100.mr-1.mb-1
            b-form-group(label-for="affairName"  label="Nom de l'affaire *")
                ejs-textbox(id="affairName" placeholder="Nom de l'affaire" v-model="affairForm.name" name="Nom" required="")
        .d-flex.flex-column.w-100.mr-1.mb-1
            b-form-group(label-for="affairCode"  label="Code *")
                ejs-textbox(id="affairCode" placeholder="Code" v-model="affairForm.code" name="Code" required="")
        .d-flex.flex-column.w-100.mr-1.mb-1
            b-form-group(label-for="affairStatus"  label="Statut")
                ejs-dropdownlist(id='affairStatus' popupHeight='300px' filterType='Contains' :allowFiltering='true' :dataSource='statusList' :fields="{ text: 'label', value: 'value' }" v-model='affairForm.status')
        .d-flex.flex-column
            b-form-group(label-for="affairColor" label="Couleur")
                ejs-colorpicker(id="affairColor" v-model="affairForm.color" mode="Palette")
    .d-flex.mt-1.w-100
        .d-flex.flex-column.w-100
            b-form-group
                b-form-checkbox.custom-control-primary(v-model='affairForm.ownAddressAffair')
                    | L'affaire est à une adresse différente de celle du client
    div(v-if='affairForm.ownAddressAffair')
        .d-flex.flex-column.mt-1.w-100
            b-form-group.w-100.mb-1(label='Adresse' label-for='address')
              vue-typeahead-bootstrap#address.not-autocomplete(type='text' v-model='affairForm.address' :ieCloseFix='false' :data='autocompleteAddressData' :serializer='item => item.properties.label' :showAllResults='true' placeholder='Rechercher une adresse ou saisissez : Rue et n° de rue' @input='autocompleteAddressSource()' @hit='autocompleteAddressSelected' autocomplete='nope' aria-autocomplete='nope')
                  template(slot='suggestion' slot-scope='{ data, htmlText }')
                    .item
                      small {{data.properties.name}}
                      span {{data.properties.postcode+ ' ' + data.properties.city}}
            div
              ejs-textbox(id="affairAddressComplement" placeholder="Suite de l'adresse" v-model="affairForm.addressComplement")
            .d-flex.mt-1.w-100
              .mr-1.w-50
                ejs-textbox.mr-1(id="affairZipCode" placeholder="Code postal" v-model="affairForm.zipCode")
              .w-50
                ejs-textbox(id="affairCity" placeholder="Ville" v-model="affairForm.city")
            .mt-1
              ejs-textbox(id="affairCountry" placeholder="Pays" v-model="affairForm.country")
            label.mt-1(for="address'") Coordonn&eacute;es GPS
            div
              ejs-textbox(id="affairLatitutde" placeholder="Latitude" v-model="affairForm.latitude")
            .mt-1
              ejs-textbox(id="affairLongitude" placeholder="Longitude" v-model="affairForm.longitude")

    vs-divider.mb-50.mt-1(color='#cccccc')
    .d-flex.w-100.flex-column
      b-form-group(label='Description' label-for='affairDescription')
        ejs-textbox(id="affairDescription" :multiline="true" v-model='affairForm.description' placeholder="Description courte de l'affaire")
    .d-flex.w-100.justify-content-between.inputs-wrapper
      .d-flex.flex-column.w-100.mr-1
        b-form-group.mt-1(label='Catégorie' label-for='affairCategory')
          ejs-dropdowntree(v-if="categoriesLoaded" ref="affairCategory" :fields='fieldsCategories' popupHeight='300' v-model="affairForm.affairCategoryId")
      .d-flex.flex-column.w-100
        b-form-group.mt-1(label='Montant' label-for='Montant')
          ejs-numerictextbox(ref="amount" id="amount" v-model="affairForm.amount" format="# ###.## €")
    .d-flex.mt-1.w-100.flex-column
      b-form-group(label="Zone de l'affaire :" label-for="affairZone")
        ejs-dropdownlist(id="affairZone" :dataSource="zoneList" placeholder="Selectionnez une zone" :fields="{ text: 'label', value: 'value' }" v-model="affairForm.zone")
    .d-flex.mt-1.w-100.flex-column
      b-form-group(label="Affaire parente :" label-for="affairParent")
        ejs-dropdownlist(id="affairParent" :dataSource="affairParents" placeholder="Selectionnez une affaire parente" :fields="{ text: 'label', value: 'value' }" v-model="affairForm.parentAffairId")
    .d-flex.w-100.flex-column
      b-form-group.mt-1(label='Date de début' label-for='affairStartDate')
          ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="affairForm.startDate" placeholder="JJ/MM/AAAA")
      b-form-group.mt-1(label='Date de fin' label-for='affairEndDate')
          ejs-datepicker(:format="'dd/MM/yyyy'" locale='fr' v-model="affairForm.endDate" placeholder="JJ/MM/AAAA")
    vs-divider.mb-50.mt-1(color='#cccccc')
    .d-flex.w-100.flex-column.mb-2.pb-2
      b-form-group(label-for="affairCollaborators"  label="Collaborateurs en charge de l'affaire")
          ejs-multiselect(v-if="collaboratorsLoaded" id='affairCollaborators' name="Collaborateurs" placeholder='Selectionnez un ou plusieurs collaborateur' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource="collaboratorsList.map(elem => { label = elem.firstName + ' ' + elem.lastName; return { ...elem, label: label }})" :fields="{ text: 'label', value: 'id' }" v-model='affairForm.collaboratorIds' required="")
              template(v-slot:footerTemplate)
                  div.p-50
                      ejs-button(v-on:click.native="newCollaborator()" :content="'+ Nouveau collaborateur'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
      b-form-group.mt-1(label="CA attribué au collaborateur :" label-for="collaborators")
        ejs-dropdownlist(id="dropdownlist" :dataSource="collaboratorsList" placeholder="Selectionnez un collaborateur" :fields="{ text: 'displayLabel', value: 'id' }" v-model="affairForm.caCollaboratorId")
    //- vs-divider.mb-50.mt-1(color='#cccccc')
    //- .d-flex.w-100.flex-column.py-1
    //-   .d-flex.justify-content-between
    //-     b-button.btn-icon(v-if='affairForm.id' variant='outline-danger' @click='archiveAffairLocal(affairForm.id, affairForm.name)')
    //-         feather-icon(icon='ArchiveIcon')
    //-     b-button-group
    //-       b-button(@click="saveAffair(false)" variant="primary")
    //-         | Enregistrer
    //-       b-button(@click="saveAffair(true)" style="border-left: 1px solid #ccc !important;" variant="primary")
    //-         feather-icon(icon='LogOutIcon')

</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { debounce } from "lodash";
import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import { affairStatus, affairZone } from "@/types/api-orisis/enums/enums";

export default {
  props: {
    affairId: {
      default: null,
      required: true,
    },
    dataLoaded: {
      default: true,
      required: false,
    },
    affairForm: {},
  },
  data() {
    return {
      categoriesLoaded: false,
      companiesLoaded: false,
      collaboratorsLoaded: false,
      autocompleteAddressData: [],
      autocompleteAddressIndex: 0,
      statusList: affairStatus,
      zoneList: affairZone,

      formObj: "",

      options: {
        rules: {
          Code: {
            required: [true, "Indiquez un code d'affaire"],
          },
          Nom: {
            required: [true, "Indiquez le nom de l'affaire"],
          },
          Collaborateurs: {
            required: [true, "Sélectionnez au moins un collaborateur"],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "affair",
      "isLoadingCompany",
      "companiesList",
      "isLoadingAffairCategoriesTreeList",
      "affairCategoriesTreeList",
      "affairsList",
      "isLoadingUser",
      "isLoadingCollaboratorsList",
      "isLoadingAffair",
      "collaboratorsList",
      "isUpdatingAffair",
      "isLoadingAffairCategoriesList",
    ]),
    fieldsCategories() {
      return {
        dataSource: this.affairCategoriesTreeList,
        value: "id",
        text: "label",
        child: "children",
      };
    },
    affairParents() {
      return this.affairsList
        .map((affair) => {
          return {
            label: affair.name,
            value: affair.id,
            text: affair.name,
          };
        })
        .filter((affair) => affair.value !== this.affairForm.id);
    },
  },
  watch: {
    "affairForm.ownAddressAffair": function (newVal, oldVal) {
      if (newVal) {
        this.$nextTick(() => {
          this.deteleAutocomplete();
        });
      }
    },
  },
  async created() {
    await this.getAffairCategoriesTree({}).then((res) => {
      this.categoriesLoaded = true;
    });
    await this.getCompanies({});
    this.companiesLoaded = true;
    this.getCollaborators({});
    this.collaboratorsLoaded = true;
  },
  async mounted() {
    this.deteleAutocomplete();
    this.formObj = new FormValidator("#formEditReport", this.options);
  },
  methods: {
    ...mapActions([
      "updateAffair",
      "archiveAffairs",
      "getCollaborators",
      "getCompanies",
      "getAffairCategoriesTree",
    ]),
    // Autocomplete Address from data.gouv
    autocompleteAddressSource: debounce(function (indexSource) {
      let _this = this;
      fetch(
        `https://api-adresse.data.gouv.fr/search/?q=${this.affairForm.address}&autocomplete=0&limit=50`
      )
        .then((response) => {
          return response.json();
        })
        .then((data) => {
          this.autocompleteAddressData = data.features;
          this.autocompleteAddressIndex = indexSource;
        });
    }, 500),
    autocompleteAddressSelected(item) {
      this.affairForm.address = item.properties.name;
      this.affairForm.city = item.properties.city;
      this.affairForm.zipCode = item.properties.postcode;
      this.affairForm.latitude = item.geometry.coordinates[1];
      this.affairForm.longitude = item.geometry.coordinates[0];
      this.affairForm.contry = "France";
    },
    deteleAutocomplete() {
      let elements = document.querySelectorAll(".not-autocomplete input");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
      elements = document.querySelectorAll(".not-autocomplete");
      if (!elements) {
        return;
      }
      elements.forEach((element) => {
        element.setAttribute("autocomplete", "nope");
      });
    },
    archiveAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveAffairs({ affairIds: [id], route: "affair" });
            this.$tabs.close();
          }
        });
    },
    saveAffair(redirectTo = false) {
      const codeAlreadyExist = this.affairsList.find(
        (affair) =>
          affair.code === this.affairForm.code &&
          affair.id !== this.affairForm.id
      );
      if (this.affairForm.code === null || this.affairForm.code === "") {
        this.affairForm.code = null;
        this.$bvModal
          .msgBoxConfirm(
            "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
            {
              title:
                "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
              size: "sm",
              okTitle: "Continuer",
              okVariant: "primary",
              cancelTitle: "Annuler",
              cancelVariant: "outline-danger",
              hideHeaderClose: true,
              centered: true,
            }
          )
          .then((value) => {
            if (value) {
              this.updateAffair({
                affair: {
                  ...this.affairForm,
                  affairCategoryId: this.affairForm.affairCategoryId?.length
                    ? this.affairForm.affairCategoryId[0]
                    : null,
                },
                route: "affair",
              });

              if (redirectTo) {
                this.$tabs.close({ to: "/affairs" });
              }
            }
          });
      } else if (codeAlreadyExist) {
        this.$bvModal.msgBoxConfirm(
          this.affairForm.code
            ? "Veuillez mettre un autre code affaire."
            : "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
          {
            title: this.affairForm.code
              ? `Le code affaire ${this.affairForm.code} existe déjà.`
              : "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
            size: "sm",
            okTitle: "D'accord",
            okVariant: "primary",
            cancelTitle: "Fermer",
            cancelVariant: "outline-danger",
            hideHeaderClose: true,
            centered: true,
          }
        );
      } else {
        this.updateAffair({
          affair: {
            ...this.affairForm,
            affairCategoryId: this.affairForm.affairCategoryId?.length
              ? this.affairForm.affairCategoryId[0]
              : null,
          },
          route: "affair",
        });
        if (redirectTo) {
          this.$tabs.close({ to: "/affairs" });
        }
      }
    },
    newCompany() {
      this.$router.push({ path: "/directory/new-company/clients/5" });
    },
    newCollaborator() {
      this.$router.push({
        name: "new-collaborator",
        params: { routeOrigine: "new-affair/" },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 750px) {
  .inputs-wrapper {
    flex-direction: column;
  }
}
</style>
