<template lang="pug">
div.w-100
    b-row.m-0
        b-col(cols='12' style='padding-bottom:0px !important')
            b-form-group(label-for="affairCustomFieldTemplateId"  label="Formulaire lié à l'affaire *")
                ejs-dropdownlist(id='affairCustomFieldTemplateId' placeholder='Selectionnez un formulaire' :footerTemplate="'footerTemplate'" popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='customFieldTemplatesList' :fields="{ text: 'label', value: 'id' }" v-model='affairForm.customFieldTemplateId'  @change="setCustomFieldTemplate")
                    template(v-slot:footerTemplate)
                        div.p-50
                            ejs-button(v-on:click.native="newCustomFieldTemplate()" :content="'+ Nouveau formulaire de champs personnalisés'" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-open-link'" cssClass="w-100" iconPosition="Right")
    //- vs-divider.mb-50.mt-1(color='#cccccc')
    div(v-if="!dataCustomFieldTemplateLoading")
        div(v-for="(group, index) of dataCustomFieldTemplate.customFieldGroups" :key="group.id")
            div(v-for="signature in signatures" :key="signature.id")
            b-row.m-0
                b-col(cols='12' style='padding-bottom:0px !important')
                    h4.m-0(:style="'color:'+group.color+' !important'") {{ group.label }}
            b-row.m-0
                b-col(cols='12' style='padding-bottom:0px !important' v-for="(customField, indexCustomField) of group.customFields" :key="indexCustomField")
                    b-form-group(v-if="customField.typeField==11")
                        b-form-checkbox.custom-control-primary( :id="'affairCustomField_'+customField.id+'_'+group.id" v-model="formAffairCustomFields[customField.id]") {{ customField.label }}
                    b-row.m-0.d-flex.flex-end.justify-content-between.align-items-end.w-full(v-else :label-for="'affairCustomField_'+customField.id+'_'+group.id" v-model="formAffairCustomFields[customField.id]")
                      label(:for="'affairCustomField_'+customField.id+'_'+group.id") {{ customField.label }}
                      div.mb-50(:key="keySignatureLocked")
                        b-button(v-if="customField.typeField === 12 && !isSignatureLocked(customField.id, group.id)" @click="lockSignature(customField.id, group.id)" variant='outline-primary')
                            feather-icon(icon='CheckIcon')
                        b-button(v-else-if="customField.typeField === 12" @click="unlockSignature(customField.id, group.id)" variant='outline-secondary')
                            feather-icon(icon='LockIcon')
                        b-button.ml-1(v-if="customField.typeField === 12 && !isSignatureLocked(customField.id, group.id)" @click="clearSignature(group.id, customField.id)" variant='outline-danger')
                            span Effacer
                    ejs-numerictextbox(v-if="customField.typeField==1 || customField.typeField==2 || customField.typeField==3" :id="'affairCustomField_'+customField.id+'_'+group.id" :label="customField.label" :value='0'  :format='customField.typeField==2 ? "########.## €" : customField.typeField==3 ? "### %" : null' v-model="formAffairCustomFields[customField.id]")
                    ejs-textbox(v-else-if="customField.typeField==4 || customField.typeField==5" :id="'affairCustomField_'+customField.id+'_'+group.id" :label="customField.label" :multiline="customField.typeField==5" v-model="formAffairCustomFields[customField.id]")
                    ejs-richtexteditor(v-else-if="customField.typeField==6" ref="rteObj" v-model="formAffairCustomFields[customField.id]" :label="customField.label" :toolbarSettings="toolbarSettings")
                    ejs-datepicker(v-else-if="customField.typeField==7" :id="'affairCustomField_'+customField.id+'_'+group.id" :format="'dd/MM/yyyy'" locale='fr' placeholder="JJ/MM/AAAA" :label="customField.label" v-model="formAffairCustomFields[customField.id]")
                    ejs-datetimepicker(v-else-if="customField.typeField==8" :id="'affairCustomField_'+customField.id+'_'+group.id" locale='fr'  :firstDayOfWeek="firstDayOfWeek" placeholder="JJ/MM/AAAA HH:MM" :label="customField.label" v-model="formAffairCustomFields[customField.id]")
                    ejs-dropdownlist(v-else-if="customField.typeField==9" :id="'affairCustomField_'+customField.id+'_'+group.id" placeholder='Selectionnez une valeur' popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='customField.options ? customField.options.split(";") : []' :label="customField.label" v-model="formAffairCustomFields[customField.id]")
                    ejs-multiselect(v-else-if="customField.typeField==10" :id="'affairCustomField_'+customField.id+'_'+group.id" placeholder='Selectionnez une valeur' popupHeight='300px' sortOrder="Ascending" filterType='Contains' :allowFiltering='true' :dataSource='customField.options ? customField.options.split(";") : []' :label="customField.label" v-model="formAffairCustomFields[customField.id]")
                    ejs-signature(v-if="customField.typeField==12" @created="loadSignaturesIntoComponents(group.id, customField.id)" :id="'affairCustomFieldSignature_'+customField.id+'_'+group.id" :name="customField.id" v-model="formAffairCustomFields[customField.id]" :ref="'signatureComponent'+ group.id + customField.id" :maxStrokeWidth="3" :minStrokeWidth="0.5" class="ejs-signature-input")
    //-         vs-divider.mb-50.mt-1(color='#cccccc')
    //- b-row.m-0
    //-     b-col(cols='12')
    //-         .d-flex.justify-content-between
    //-             div
    //-                 b-button.btn-icon(v-if='affairForm.id' variant='outline-danger' @click='archiveAffairLocal(affairForm.id, affairForm.name)')
    //-                     feather-icon(icon='ArchiveIcon')
    //-             ejs-button(v-on:click.native="saveAffair()" content="Enregistrer" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")

</template>
<script>
import { mapGetters, mapActions } from "vuex";

import { FormValidator } from "@syncfusion/ej2-vue-inputs";
import { getComponent } from "@syncfusion/ej2-base";
import Vue from "vue";
import {
  RichTextEditorPlugin,
  Toolbar,
  Link,
  Image,
  Count,
  HtmlEditor,
  QuickToolbar,
  Table,
} from "@syncfusion/ej2-vue-richtexteditor";

Vue.use(RichTextEditorPlugin);

export default {
  provide: {
    richtexteditor: [
      Toolbar,
      Link,
      Image,
      Count,
      HtmlEditor,
      QuickToolbar,
      Table,
    ],
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
    affairForm: {},
  },
  data() {
    return {
      keySignatureLocked: 0,
      firstDayOfWeek: 1,
      dataCustomFieldTemplate: null,
      dataCustomFieldTemplateLoading: true,
      formAffairCustomFields: {},

      formObj: "",

      base64SignatureImage: null,
      signatures: [],
      toolbarSettings: {
        items: [
          "Bold",
          "Italic",
          "Underline",
          "StrikeThrough",
          "FontName",
          "FontSize",
          "FontColor",
          "BackgroundColor",
          "LowerCase",
          "UpperCase",
          "SuperScript",
          "SubScript",
          "EmojiPicker",
          "|",
          "Formats",
          "Alignments",
          "NumberFormatList",
          "BulletFormatList",
          "Outdent",
          "Indent",
          "|",
          "CreateTable",
          "CreateLink",
          "|",
          "ClearFormat",
          "Print",
          "SourceCode",
          "FullScreen",
          "|",
          "Undo",
          "Redo",
        ],
      },
      options: {
        rules: {
          Code: {
            required: [true, "Indiquez un code d'affaire"],
          },
          Nom: {
            required: [true, "Indiquez le nom de l'affaire"],
          },
          Collaborateurs: {
            required: [true, "Sélectionnez au moins un collaborateur"],
          },
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      "affair",
      "customFieldTemplatesList",
      "isLoadingAffair",
      "isUpdatingAffair",
    ]),
  },
  async created() {
    if (this.affair.customFieldTemplateId) {
      this.setCustomFieldTemplate({
        itemData: this.customFieldTemplatesList.find(
          (el) => el.id == this.affair.customFieldTemplateId
        ),
      });
    }
  },
  mounted() {
    this.formObj = new FormValidator("#formEditReport", this.options);
  },
  methods: {
    ...mapActions([
      "updateAffair",
      "updateAffairCustomField",
      "archiveAffairs",
    ]),
    lockSignature(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "affairCustomFieldSignature_" + customFieldId + "_" + groupId
      );

      if (signatureComponent) {
        getComponent(signatureComponent, "signature").isReadOnly = true;
      }

      this.keySignatureLocked++;
    },
    unlockSignature(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "affairCustomFieldSignature_" + customFieldId + "_" + groupId
      );

      if (signatureComponent) {
        getComponent(signatureComponent, "signature").isReadOnly = false;
      }

      this.keySignatureLocked++;
    },
    isSignatureLocked(customFieldId, groupId) {
      const signatureComponent = document.getElementById(
        "affairCustomFieldSignature_" + customFieldId + "_" + groupId
      );

      if (signatureComponent) {
        return getComponent(signatureComponent, "signature").isReadOnly;
      } else {
        return false;
      }
    },
    setCustomFieldTemplate(args) {
      this.formAffairCustomFields = {};
      this.dataCustomFieldTemplateLoading = true;
      this.dataCustomFieldTemplate = args.itemData;
      this.dataCustomFieldTemplate.customFieldGroups.sort((a, b) =>
        a.position > b.position ? 1 : b.position > a.position ? -1 : 0
      );

      this.dataCustomFieldTemplate.customFieldGroups.forEach((group) => {
        group.customFields.forEach((field) => {
          if (field.typeField == 12) {
            const signatureData = this.affair.customFields.find(
              (cf) => cf.customField.id == field.id
            );
            if (signatureData) {
              this.signatures.push({
                id: field.id,
                refName: "signatureComponent" + field.id,
                base64Data: signatureData.value,
              });

              this.formAffairCustomFields[field.id] = signatureData.value;
            }
          } else {
            const customField = this.affair.customFields
              .map((el) => ({ customField: el.customField, value: el.value }))
              .find((el) => el.customField.id == field.id);
            if (customField) {
              this.formAffairCustomFields[field.id] =
                customField.customField.typeField === 10 &&
                !Array.isArray(customField.value)
                  ? customField.value.split(";")
                  : customField.value;
            } else {
              this.formAffairCustomFields[field.id] = field.defaultValue;
            }
          }
        });
      });
      this.dataCustomFieldTemplateLoading = false;
    },
    archiveAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.",
          {
            title: 'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveAffairs({ affairIds: [id], route: "affair" });
            this.$tabs.close();
          }
        });
    },
    async saveAffair() {
      const signatureElements = document.querySelectorAll('[id*="Signature"]');

      for (let element of signatureElements) {
        const signatureComponent = getComponent(
          document.getElementById(element.id),
          "signature"
        );
        const base64Signature = signatureComponent.getSignature();
        let id = element.id.split("_")[1];

        const existingSignatureIndex = this.signatures.findIndex(
          (sig) => sig.id === id
        );
        if (existingSignatureIndex >= 0) {
          this.signatures[existingSignatureIndex].base64Data = base64Signature;
          signatures.load(signatureElements);
        } else {
          this.signatures.push({ id: id, base64Data: base64Signature });
        }

        if (signatureComponent.load) {
          signatureComponent.load(base64Signature);
        }

        this.formAffairCustomFields[id] = base64Signature;
      }
      await this.updateAffairCustomField({
        affairId: this.affairId,
        affairCustomFields: this.formAffairCustomFields,
      });

      await this.$emit("saveAffair");
    },
    loadSignaturesIntoComponents(groupId, customFieldId) {
      const signatureComponent =
        this.$refs["signatureComponent" + groupId + customFieldId][0];
      const signatureData = this.affair.customFields.find(
        (cf) => cf.customField.id === customFieldId
      );
      if (signatureComponent && signatureData) {
        this.lockSignature(customFieldId, groupId);
        signatureComponent.load(signatureData.value);
      }
    },
    clearSignature(groupId, customFieldId) {
      const signatureComponent =
        this.$refs["signatureComponent" + groupId + customFieldId][0];
      if (signatureComponent) {
        signatureComponent.clear();
        this.formAffairCustomFields[customFieldId] = "";
      }
    },
    newCustomFieldTemplate() {
      this.$router.push({ path: "/parameters/new-custom-field-template" });
    },
  },
};
</script>
<style lang="scss">
@import "~@syncfusion/ej2-vue-richtexteditor/styles/bootstrap5.css";
</style>
