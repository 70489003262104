<template lang="pug">
div
    ejs-chart#chartcontainer(v-if="loaded" style='display:block' theme='bootstrap5' align='center'  :primaryXAxis='primaryXAxis' :primaryYAxis='primaryYAxis' :chartArea='chartArea' :width='width' :legendSettings='legendSettings' :tooltip='tooltip' :palettes="palettes"  :textRender="textRender" )
        e-series-collection
            e-series(:dataSource='seriesData' type='Column' xName='date' yName='sumForecastOutput' name='Charges prévisionnelles' groupName='output' columnWidth='1' opacity='1' :marker='markerForecastOutput' columnSpacing='0.1')  
            e-series(:dataSource='seriesData' type='Column' xName='date' yName='sumCountedOutput' name='Charges réélles' groupName='output' columnWidth='1' opacity='.5' :marker='marker' columnSpacing='0.1')  

            e-series(:dataSource='seriesData' type='Column' xName='date' yName='sumForecastInput' name='CA prévisionnel' groupName='input' columnWidth='1' opacity='.5' :marker='markerForecastInput' columnSpacing='0.1')  
            e-series(:dataSource='seriesData' type='Column' xName='date' yName='sumCountedInput' name='CA réél'  groupName='input' columnWidth='1' opacity='1' :marker='marker' columnSpacing='0.1')  

            e-series(:dataSource='seriesData' type='Line' xName='date' yName='margeCounted' name='Marge réélle' groupName='marge' width='3' opacity='1' :marker='circleMarker' )  
            e-series(:dataSource='seriesData' type='Line' xName='date' yName='margeForecast' name='Marge prévisionnelle' groupName='marge' dashArray=10 width='3' opacity='1' :marker='circleMarker')  
</template>

<script>
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { Browser } from "@syncfusion/ej2-base";
import {
  LineSeries,
  StackingColumnSeries,
  Tooltip,
  ColumnSeries,
  Category,
  Legend,
  DataLabel,
  Highlight,
  ChartAnnotation,
} from "@syncfusion/ej2-vue-charts";
import VueApexCharts from "vue-apexcharts";

export default {
  components: {
    VueApexCharts,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
    dateRange: {
      default: null,
      required: true,
    },
  },
  provide: {
    chart: [
      StackingColumnSeries,
      LineSeries,
      Category,
      ColumnSeries,
      Legend,
      Tooltip,
      Highlight,
      DataLabel,
      ChartAnnotation,
    ],
  },
  data() {
    return {
      title: "Tableau de bord",
      name: "dashboard",
      loaded: false,

      seriesData: [],

      //Initializing Primary X Axis
      primaryXAxis: {
        title: "",
        labelIntersectAction: "Rotate45",
        valueType: "Category",
        majorTickLines: { width: 0 },
      },

      //Initializing Primary Y Axis
      primaryYAxis: {
        title: "",
        lineStyle: { width: 0 },
        majorTickLines: { width: 0 },
        // labelFormat: '{value} €',
      },
      chartArea: {
        border: {
          width: 0,
        },
      },
      markerLine: {
        visible: true,
        width: 7,
        height: 7,
      },
      circleMarker: {
        visible: true,
        height: 7,
        width: 7,
        shape: "Circle",
        isFilled: true,
      },
      marker: {
        visible: false,
        width: 7,
        height: 7,
      },
      markerForecastOutput: {
        visible: false,
        shape: "Rectangle",
        dataLabel: {
          visible: true,
          position: "Outer",
          margin: { top: 70 },
          template: "markerForecastOutput",
        },
        width: 2,
      },
      markerForecastInput: {
        visible: false,
        shape: "Rectangle",
        dataLabel: {
          visible: true,
          position: "Outer",
          margin: { top: 70 },
          template: "markerForecastInput",
        },
        width: 2,
      },

      width: "100%",
      legendSettings: {
        visible: true,
        enableHighlight: false,
      },
      tooltip: {
        enable: true,
      },
      palettes: [
        "#dc3545",
        "#dc3545",
        "#28a745",
        "#28a745",
        "#0c3571",
        "#ff9f43",
      ],
    };
  },
  computed: {
    ...mapGetters([
      "affairCostingsListByAffair",
      "affairCostingFilters",
      "isLoadingAffairCostingsList",
    ]),
  },
  watch: {
    isLoadingAffairCostingsList: function (newVal, oldVal) {
      if (!newVal && oldVal) this.getSeriesDataSyncFusion();
    },
  },
  async created() {
    this.getSeriesDataSyncFusion();
  },
  methods: {
    ...mapActions([]),
    textRender(args) {
      args.template =
        args.series.name === "CA prévisionnel"
          ? "markerForecastInput"
          : "markerForecastOutput";
    },
    getSeriesDataSyncFusion() {
      this.loaded = false;
      this.seriesData = [];
      if (this.dateRange) {
        let dateDebut = this.dateRange[0];
        let dateFin = this.dateRange[1];
        let dateActuelle = new Date(dateDebut);

        while (dateActuelle <= dateFin) {
          let dataOfMonth = this.affairCostingsListByAffair.filter(
            (el) =>
              dayjs(el.date).format("YYYY-MM-DD") ==
              dayjs(dateActuelle).startOf("month").format("YYYY-MM-DD")
          );
          let sumCountedInput = 0;
          let sumForecastInput = 0;
          let sumCountedOutput = 0;
          let sumForecastOutput = 0;
          let margeCounted = 0;
          let margeForecast = 0;
          dataOfMonth.forEach((obj) => {
            if (obj.sourceType == 1) {
              if (obj.directionType == 1) {
                sumCountedInput += obj["amountUnit"] * obj["valuatedQuantity"];
                margeCounted += obj["amountUnit"] * obj["valuatedQuantity"];
              } else {
                sumCountedOutput += obj["amountUnit"] * obj["valuatedQuantity"];
                margeCounted -= obj["amountUnit"] * obj["valuatedQuantity"];
              }
            } else {
              if (obj.directionType == 1) {
                sumForecastInput += obj["amountUnit"] * obj["valuatedQuantity"];
                margeForecast += obj["amountUnit"] * obj["valuatedQuantity"];
              } else {
                sumForecastOutput +=
                  obj["amountUnit"] * obj["valuatedQuantity"];
                margeForecast -= obj["amountUnit"] * obj["valuatedQuantity"];
              }
            }
          });
          let dateStr = dayjs(dateActuelle)
            .startOf("month")
            .format("MMMM YYYY");
          this.seriesData.push({
            date: dateStr.charAt(0).toUpperCase() + dateStr.slice(1),
            sumCountedInput: sumCountedInput,
            sumForecastInput: sumForecastInput,
            sumCountedOutput: sumCountedOutput,
            sumForecastOutput: sumForecastOutput,
            margeCounted: margeCounted,
            margeForecast: margeForecast,
          });

          if (dateActuelle.getMonth() === 11) {
            dateActuelle.setFullYear(dateActuelle.getFullYear() + 1);
            dateActuelle.setMonth(0);
          } else {
            dateActuelle.setMonth(dateActuelle.getMonth() + 1);
          }
        }
      }
      this.$nextTick(async () => {
        this.loaded = true;
      });
    },
  },
};
</script>
