<template lang="pug">
div.w-100.h-100
    HeaderGrid(:title="title" :uid="uid" :name="name" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" :archiveMode="archiveMode" :archive="archive" @refreshRow="refreshRow" :selectionSettings="selectionSettings" :indexSearch="indexSearch")
    .content-grid.d-flex.w-100(style="height: calc(100% - 62px);")
      .text-center.flex-center.w-100(v-if='isLoadingMissionsListByAffairId || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
            br
            br
            br
            | Chargement de la liste des missions..
        p Gérer les missions à réaliser sur votre affaire
      sync-tree-grid#overviewtreegrid(ref="overviewtreegrid" v-else :editSettings='editSettings' :parentIdMapping="parentIdMapping" :idMapping="idMapping" :hasChildMapping="hasChildMapping" :treeColumnIndex="treeColumnIndex" :allowGrouping="allowGrouping" :gridData="dataFiltered" :name="name" :headerData="headerData" @rowClicked="onRowClicked" @setSelectedRows="setSelectedRows" :tagguerButton="tagguerButton" @this.$refs.overviewtreegrid="this.$refs.overviewtreegrid" @deleteButtonClicked="deleteButtonClicked" @actionBegin="actionBegin" @archiveButtonClicked="archiveButtonClicked")
    //- popup-catalog-missions(ref="popupCatalogMissions" :affairId="affairId" @closePopupCatalogMission="closePopupCatalogMission")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncTreeGrid from "@/components/global/grid/TreeGrid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import PopupCatalogMissions from "@/components/report/PopupCatalogMissions";

export default {
  components: {
    SyncTreeGrid,
    HeaderGrid,
    PopupCatalogMissions,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      title: "Missions",
      name: "affairMissions",
      uid: "affairMissions",
      archive: false,
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      uid: "affairMissions",
      indexSearch: 0,

      dateRanger: false,
      tvaModeButton: false,
      addButton: true,
      addButton2: false,
      searchButton: true,
      tagguerButton: false,
      archiveMode: false,
      searchPlaceholder: "Rechercher une mission",
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        titleAdd: "Nouvelle mission",
        titleEdit: "Modifier la mission",
        titleField: "label",
      },
      allowGrouping: false,
      parentIdMapping: "parentId",
      idMapping: "id",
      hasChildMapping: "parentId",
      treeColumnIndex: 1,
      selectionSettings: {
        type: "Single",
        mode: "Row",
        enableToggle: true,
        checkboxMode: "ResetOnRowClick",
      },
    };
  },
  computed: {
    ...mapGetters([
      "missionsListByAffairId",
      "isLoadingMissionsListByAffairId",
      "institutionSelected",
      "workspaceSelected",
      "unitsList",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
      "userParametersList",
    ]),
    dataFiltered() {
      return this.$store.getters["missionsListByAffairId"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsListByAffairId.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          displayLabel: elem.analyticCode + " - " + elem.label,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
  },
  async created() {
    if (!this.unitsList || this.unitsList.length == 0) {
      await this.getUnits({});
    }
    if (!this.analyticsSettingsList || this.analyticsSettingsList.length == 0) {
      await this.getAnalyticsSettings({});
    }
    await this.getMissionsByAffairId({
      affairId: this.affairId,
    }).then(async (res) => {
      this.setHeaderData();
      if (res.length) {
        await this.setColumnsByObject(res[0], null, this);
        this.indexSearch++;
      }
      this.headerLoaded = true;
    });
  },
  methods: {
    ...mapActions([
      "getMissionsByAffairId",
      "createMission",
      "updateMission",
      "archiveMissions",
      "deleteMissions",
      "getUnits",
      "getAnalyticsSettings",
    ]),
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "checkbox",
          headerText: null,
          width: 30,
          maxWidth: 30,
          visible: false,
        },
        {
          type: "string",
          field: "label",
          headerText: "Mission",
          width: "auto",
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "analyticsSettingId",
          filterTemplate: "analyticsSettingFilterTemplate",
          headerText: "Code Analytique",
          width: 160,
          minWidth: 160,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "displayLabel",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "unitId",
          filterTemplate: "unitFilterTemplate",
          headerText: "Unité",
          width: 120,
          minWidth: 120,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          edit: {
            source: this.unitsList,
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
        },
        // {
        //     headerText:"Estimation",
        //     customCss:"text-primary",
        //     customAttributes:{class: 'text-primary'},
        //     columns:[
        //         {
        //         type: "number",
        //         field: "estimatedUnitPrice",
        //         headerText: "Prix U.",
        //         width:  120,
        //         minWidth: 120,
        //         allowFiltering: true,
        //         allowSorting: true,
        //         allowEditing: true,
        //         showInColumnChooser:true,
        //         visible:true,
        //         editType: "numericedit",
        //         textAlign: "Center",
        //         // edit:{ params: { decimals: 2, value: 0 } },
        //         customCss:"text-primary",
        //         customAttributes:{class: 'text-primary'},
        //         },
        //         {
        //         type: "number",
        //         field: "estimatedQuantity",
        //         headerText: "Qté prévue",
        //         width:  120,
        //         minWidth: 120,
        //         allowFiltering: true,
        //         allowSorting: true,
        //         allowEditing: true,
        //         showInColumnChooser:true,
        //         visible:true,
        //         editType: "numericedit",
        //         textAlign: "Center",
        //         // edit:{ params: { decimals: 2, value: 0 } },
        //         customCss:"text-primary",
        //         customAttributes:{class: 'text-primary'},
        //         },
        //     ]
        // },
        // {
        //     headerText:"Avancement",
        //     customCss:"text-success",
        //     customAttributes:{class: 'text-success'},
        //     columns:[
        //         {
        //         type: "number",
        //         field: "advancedActualQuantity",
        //         headerText: "Qté réalisée",
        //         width:  120,
        //         minWidth: 120,
        //         allowFiltering: true,
        //         allowSorting: true,
        //         allowEditing: false,
        //         showInColumnChooser:true,
        //         visible:true,
        //         editType: "numericedit",
        //         textAlign: "Center",
        //         customCss:"text-success",
        //         customAttributes:{class: 'text-success'},
        //         // edit:{ params: { decimals: 2, value: 0 } }
        //         },
        //         {
        //         type: "number",
        //         field: "advancedValuatedQuantity",
        //         headerText: "Qté valorisée",
        //         width:  120,
        //         minWidth: 120,
        //         allowFiltering: true,
        //         allowSorting: true,
        //         allowEditing: false,
        //         showInColumnChooser:true,
        //         visible:true,
        //         editType: "numericedit",
        //         textAlign: "Center",
        //         customCss:"text-success",
        //         customAttributes:{class: 'text-success'},
        //         // edit:{ params: { decimals: 2, value: 0 } }
        //         },
        //     ]
        // },
        {
          type: "number",
          field: "parentId",
          headerText: "Mission Parente",
          width: 1,
          maxWidth: 1,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.dataFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.dataFiltered,
            },
            type: "dropdowntree",
          },
        },
      ];
    },
    async actionBegin(args) {
      if (args.requestType === "save" && args.action === "add") {
        this.createMission({
          route: "affair",
          mission: {
            ...args.data,
            id: 0,
            parentId: !args.data.parentId ? 0 : args.data.parentId.toString(),
            analyticsSettingId: args.data.analyticsSettingId.toString(),
            estimatedQuantity: args.data.estimatedQuantity
              ? args.data.estimatedQuantity
              : 0,
            estimatedUnitPrice: args.data.estimatedUnitPrice
              ? args.data.estimatedUnitPrice
              : 0,
            advancedActualQuantity: args.data.advancedActualQuantity
              ? args.data.advancedActualQuantity
              : 0,
            advancedValuatedQuantity: args.data.advancedValuatedQuantity
              ? args.data.advancedValuatedQuantity
              : 0,
            affairId: this.affairId,
          },
        }).then(async (res) => {
          await this.setHeaderData();
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
          // this.$refs.overviewtreegrid.$refs.overviewtreegrid.updateCell(args.index, "id", res.id) //{rowIndex : args.index, field : "id", value : res.id})
        });
      } else if (args.requestType === "save" && args.action === "edit") {
        await this.updateMission({
          mission: {
            ...args.data,
            parentId: !args.data.parentId ? 0 : args.data.parentId.toString(),
            analyticsSettingId: args.data.analyticsSettingId.toString(),
            estimatedQuantity: args.data.estimatedQuantity
              ? args.data.estimatedQuantity
              : 0,
            estimatedUnitPrice: args.data.estimatedUnitPrice
              ? args.data.estimatedUnitPrice
              : 0,
            advancedActualQuantity: args.data.advancedActualQuantity
              ? args.data.advancedActualQuantity
              : 0,
            advancedValuatedQuantity: args.data.advancedValuatedQuantity
              ? args.data.advancedValuatedQuantity
              : 0,
            affairId: this.affairId,
          },
          route: "affair",
        }).then(async (res) => {
          await this.setHeaderData();
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
        });
      } else if (args.requestType === "add") {
        if (
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords() &&
          this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords()
            .length > 0
        ) {
          let oldData =
            this.$refs.overviewtreegrid.$refs.overviewtreegrid.getSelectedRecords()[0];
          args.rowData.parentId = [oldData.parentId.toString()];
          args.rowData.analyticsSettingId = [];
        }
      }
    },
    clickAction() {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.addRecord();
    },
    // clickAction2() {
    //   this.$bvModal.show('modal-catalog-missions')
    //   this.$refs['popupCatalogMissions'].popupCatalogMissionsEvent()
    // },
    onRowClicked() {},
    async archiveButtonClicked(args) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      await this.archiveMissions({ missionIds: [args.rowData.id] });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    async deleteButtonClicked(args) {
      // this.$refs.overviewtreegrid.$refs.overviewtreegrid.deleteRow(args.row)
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.closeEdit();
      await this.deleteMissions({ missionIds: [args.rowData.id] });
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewtreegrid.onFilterTextBoxChanged(search);
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewtreegrid.exportData(args.item.type);
      } else if (args.item.type == "archive") {
        this.archive = true;
      } else if (args.item.type == "current") {
        this.archive = false;
      } else {
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.openColumnChooser();
      }
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    refreshRow(val) {
      this.$refs.overviewtreegrid.$refs.overviewtreegrid.setRowData(
        val.id,
        val
      );
    },
    closePopupCatalogMission(val) {
      for (let index = 0; index < val.length; index++) {
        const row = val[index];
        this.$refs.overviewtreegrid.$refs.overviewtreegrid.addRecord({
          ...row,
          id: 0,
          parentId: 0,
          analyticsSettingId: row.analyticsSettingId.toString(),
          affairId: this.affairId,
        });
      }
    },
  },
};
</script>
