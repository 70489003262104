<template lang="pug">
div#dashboard.h-100
    HeaderGrid(:title="title" :uid="uid" userFilter="affairCostingFilters" :name="name" :dateRanger="dateRanger" :dateRangeDefault="dateRangeDefault" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @setDates="setDates" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @clickActionGroup="clickActionGroup" :optionButton="optionButton" :indexSearch="indexSearch")
    div.card-list-wrapper.w-100
      div.w-100.d-flex.px-1
            div.cursor-pointer.col-card-list-stats.w-100
              .card-list-stats.border-warning.w-100(style="box-shadow: none !important; max-width: none !important;")
                .card-list-stats-title
                    div
                        span.card-list-stats-title-badge.material-icons.text-warning.mr-50 ads_click
                        span.card-list-stats-title-text Prévu
                    .card-list-stats-title-badge.bg-warning.text-white 
                      span {{ getSumCounts(2) }}
                .card-sum.d-flex
                    div
                        span.text-success +
                        span {{ formatCurrency(getSumCosts(2, 1)) }} 
                    span.mx-50 |
                    div
                        span.text-danger -
                        span {{ formatCurrency(getSumCosts(2, 2)) }} 
                    div &nbsp;
            div.cursor-pointer.pl-1.col-card-list-stats.w-100
                .card-list-stats.border-warning.w-100(style="box-shadow: none !important; max-width: none !important;")
                    .card-list-stats-title
                        div
                            span.card-list-stats-title-badge.material-icons.text-primary.mr-50 done_outline
                            span.card-list-stats-title-text Réél
                        .card-list-stats-title-badge.bg-primary.text-white {{ getSumCounts(1) }}
                    .card-sum.d-flex
                        div
                            span.text-success +
                            span {{ formatCurrency(getSumCosts(1, 1)) }} 
                        span.mx-50 |
                        div
                            span.text-danger -
                            span {{ formatCurrency(getSumCosts(1, 2)) }} 
                        div &nbsp;
            div.cursor-pointer.pl-1.col-card-list-stats.w-100
                .card-list-stats.w-100(:class="getMarge(2)>0 ? 'border-success' : 'border-danger'" style="box-shadow: none !important; max-width: none !important;")
                    .card-list-stats-title
                        div
                            span.card-list-stats-title-badge.material-icons.mr-25(:class="getMarge(2)>0 ? 'text-success' : 'text-danger'") euro
                            span.card-list-stats-title-text Marge prévue
                        .card-list-stats-title-badge.text-white(:class="getMarge(2)>0 ? 'bg-success' : 'bg-danger'") {{ getPercentMarge(2) }}
                    .card-sum(:class="getMarge(2)>0 ? 'text-success' : 'text-danger'")
                        | {{ formatCurrency(getMarge(2)) }} 
                        span 
            div.cursor-pointer.pl-1.col-card-list-stats.w-100
                    .card-list-stats.w-100(:class="getMarge(1)>0 ? 'border-success' : 'border-danger'" style="box-shadow: none !important; max-width: none !important;")
                        .card-list-stats-title
                            div
                                span.card-list-stats-title-badge.material-icons.mr-25(:class="getMarge(1)>0 ? 'text-success' : 'text-danger'") euro
                                span.card-list-stats-title-text Marge réélle
                            .card-list-stats-title-badge.text-white(:class="getMarge(1)>0 ? 'bg-success' : 'bg-danger'") {{ getPercentMarge(1) }}
                        .card-sum(:class="getMarge(1)>0 ? 'text-success' : 'text-danger'")
                            | {{ formatCurrency(getMarge(1)) }} 
                            span 
    .content-grid-in-card-with-cards.d-flex.w-100.px-1(style="height: calc(100% - 174px);")
        .d-flex.h-100.m-0.w-100
            div.w-100.pr-1
                .cart-chart
                    h4.mb-1 Avancement de l'affaire
                    global-chart-affair(ref="globalChartAffair" :affairId="affairId" :dateRange="dateRangeDefault")
            div.w-100
                .cart-chart
                    h4.mb-1 Répartition par mission
                    repartition-mission-chart-affair(ref="repartitionMissionChartAffair" :affairId="affairId" :dateRange="dateRangeDefault")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import StatCards from "@/components/global/grid/StatCards.vue";
import GlobalChartAffair from "@/components/directory/affair/charts/GlobalChartAffair";
import RepartitionMissionChartAffair from "@/components/directory/affair/charts/RepartitionMissionChartAffair";

export default {
  components: {
    HeaderGrid,
    GlobalChartAffair,
    RepartitionMissionChartAffair,
    StatCards,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      indexSearch: 0,
      title: "Synthèse",
      name: "affairCosting",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      uid: "affairDashboard",
      dateRanger: true,
      tvaModeButton: false,
      addButton: false,
      addButton2: false,
      searchButton: false,
      tagguerButton: false,
      searchPlaceholder: "",
      allowKeyboard: false,
      allowGrouping: true,
      optionButton: false,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Dialog",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouveau budget",
        titleEdit: "Modifier le budget",
        titleField: "label",
      },
    };
  },
  computed: {
    ...mapGetters([
      "affairCostingsListByAffair",
      "isLoadingAffairCostingsListByAffair",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "institutionSelected",
      "workspaceSelected",
      "userParametersList",
    ]),
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataOrigine() {
      return this.$store.getters["affairCostingsListByAffair"];
    },
  },
  async created() {
    if (!this.affairsList || this.affairsList.length == 0) {
      await this.getAffairs({});
    }
    if (!this.missionsList || this.missionsList.length == 0) {
      await this.getMissions({});
    }
    if (!this.analyticsSettingsList || this.analyticsSettingsList.length == 0) {
      await this.getAnalyticsSettings({});
    }
    this.getCollaborators({});
    let filter = this.userParametersList.find(
      (el) => el.key == "affairCostingFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getAnalyticsSettings",
      "getAffairCostingsByAffairId",
      "getCollaborators",
      "getAffairs",
      "getMissions",
      "createAffairCosting",
      "updateAffairCosting",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,

    setHeaderData() {
      this.headerData = [
        {
          type: "string",
          field: "date",
          headerText: "Mois",
          width: 140,
          maxWidth: 140,
          // format: "Md",
          // format: "formatDateOptions",
          template: "monthTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          defaultValue: new Date(),
          showInColumnChooser: true,
          visible: true,
          edit: { type: "monthpicker" },
        },
        {
          type: "string",
          field: "sourceType",
          headerText: "Origine",
          textAlign: "Center",
          width: 100,
          minWidth: 100,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          template: "tagsTemplate",
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 2,
          edit: {
            source: [
              { label: "Réalisé", id: 1, color: "#0c3571" },
              { label: "Prévu", id: 2, color: "#f9a825ff" },
            ],
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
        },
        {
          type: "string",
          field: "directionType",
          headerText: "Type",
          width: 90,
          minWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          template: "directionTypeTemplate",
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 1,
          edit: {
            source: [
              { label: "Entrée", id: 1 },
              { label: "Sortie", id: 2 },
            ],
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
        },
        {
          type: "number",
          field: "missionId",
          headerText: "Mission",
          width: 200,
          minWidth: 200,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          visible: false,
          showFooterSum: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.missionsListFiltered,
            fields: {
              text: "label",
              value: "id",
              parentValue: "parentId",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.missionsListFiltered,
            },
            type: "dropdowntreedependencelabel",
            name: "dropdownmission",
          },
        },
        {
          type: "number",
          field: "analyticsSettingId",
          headerText: "Code analytique lié",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: false,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "analyticCode",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
            name: "dropdowntreeanalytics",
          },
          validationRules: { required: true },
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          width: "auto",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          edit: { type: "textbox", name: "textboxlabel" },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          headerText: "Qté réelle",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "valuatedQuantity",
          headerText: "Qté valorisée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountUnit",
          headerText: "Mt Unitaire",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 2, step: 0.05 } },
          format: "formatCurrency",
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amount",
          headerText: "Mt Total",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: false,
          allowFiltering: true,
          allowSorting: true,
          allowAggregate: true,
          showInColumnChooser: true,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          valueAccessor: "sumAffairCostingCurrencyFormatter",
        },
        {
          type: "string",
          field: "documentApplicationType",
          headerText: "Type Document lié",
          width: 140,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: false,
        },
        {
          type: "string",
          field: "documentPath",
          headerText: "URL Document lié",
          width: 140,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: false,
        },
      ];
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];

        await this.getAffairCostingsByAffairId({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          affairId: this.affairId,
          institutionId: this.institutionSelected.id,
          sourceType: null,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    getSumCounts(sourceType) {
      let sum = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          sum += obj["valuatedQuantity"];
        });
      return sum.toFixed(0);
    },
    getSumCosts(sourceType, directionType) {
      let sum = 0;
      this.dataOrigine
        .filter(
          (elem) =>
            elem.sourceType == sourceType && elem.directionType == directionType
        )
        .forEach((obj) => {
          sum += obj["amountUnit"] * obj["valuatedQuantity"];
        });
      return sum;
    },
    getPercentMarge(sourceType) {
      let sumInput = 0;
      let sumOutput = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          if (obj.directionType == 1) {
            sumInput += obj["amountUnit"] * obj["valuatedQuantity"];
          } else {
            sumOutput += obj["amountUnit"] * obj["valuatedQuantity"];
          }
        });
      if (sumInput == 0) {
        return "0 %";
      } else {
        return formatNumber(((sumOutput - sumInput) / sumInput) * 100) + " %";
      }
    },
    getMarge(sourceType) {
      let sum = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          if (obj.directionType == 1) {
            sum += obj["amountUnit"] * obj["valuatedQuantity"];
          } else {
            sum -= obj["amountUnit"] * obj["valuatedQuantity"];
          }
        });
      return sum;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async actionBegin(args) {
      if (args.requestType === "beginEdit" || args.requestType === "add") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "missionId"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "analyticsSettingId"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "amount"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
        }
      }
      if (args.requestType === "save" && args.action === "add") {
        await this.createAffairCosting({
          affairCosting: {
            ...args.data,
            id: 0,
            affairId: this.affairId,
            missionId: args.data.missionId.toString(),
          },
          route: "affair",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        // On update le budget principal
        await this.updateAffairCosting({
          affairCosting: {
            ...args.data,
            affairId: this.affairId,
            missionId: args.data.missionId.toString(),
          },
          route: "affair",
        });
      }
      if (args.requestType === "save") {
        for (
          var i = 0;
          i < this.$refs.overviewgrid.$refs.overviewgrid.getColumns().length;
          i++
        ) {
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "missionId"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "analyticsSettingId"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = false;
          }
          if (
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[i].field ==
            "amount"
          ) {
            this.$refs.overviewgrid.$refs.overviewgrid.getColumns()[
              i
            ].visible = true;
          }
        }
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
    },
    async deleteButtonClicked(args) {},
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    clickActionGroup(val) {
      this.selectedRows.forEach((row) => {
        let dataUpdate = {
          ...row,
          status: val,
          collaboratorId: row.collaborator.id,
          payrollEventId: row.payrollEvent.id,
        };
        this.updateAffairCosting({
          affairCosting: dataUpdate,
          route: "affair",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.setRowData(
          dataUpdate.id,
          dataUpdate
        );
      });
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 900px) {
  .content-grid-in-card-with-cards > div {
    flex-direction: column !important;
    & > div {
      padding-right: 0 !important;
    }
  }
}
</style>
