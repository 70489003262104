<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" userFilter="affairCreditsFilters" :dateRangeDefault="dateRangeDefault" :title="title" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch")
    StatCards
         div.cursor-pointer.pl-1.col-card-list-stats(@click="changeTab('all')")
          .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                span.card-list-stats-title-text TOUT
              .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
            .card-sum
              | {{ getSumDataByStatus("all") }} 
              span {{ tvaMode }}
         div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" @click="changeTab(status.value)")
          .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.mr-25(:class="'text-'+status.class") {{ status.icon }}
                span.card-list-stats-title-text {{ status.label }}
              .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value) }}
            .card-sum
              | {{ getSumDataByStatus(status.value) }} 
              span {{ tvaMode }}
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingDocumentsListByAffair || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des avoirs...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-document-content(ref='sidebarDocumentContent' @closeSidebar="closeSidebar")
    popup-new-credit(ref='popupNewCredit' :activeTab="activeTab" :activePopupCredit="activePopupCredit" @toggleModalNewCredit="toggleModalNewCredit" @activeTabChange="activeTabChange" :affairId="affairId" :companyId="affair.companyId ? affair.companyId : affair.company ? affair.company.id : null")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { creditStatus } from "@/types/api-orisis/enums/enums";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import StatCards from "@/components/global/grid/StatCards.vue";
import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarDocumentContent from "@/components/invoice/SidebarDocumentContent";
import PopupNewCredit from "@/components/invoice/PopupNewCredit.vue";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarDocumentContent,
    PopupNewCredit,
    SyncGrid,
    HeaderGrid,
    StatCards,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      title: "Avoirs",
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "credit",
      uid: "affairCredits",
      statusList: creditStatus.filter((el) => !el.notUse),
      headerData: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},
      addButton: true,

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher un avoir",

      activePopupCredit: false,
      activeTab: "blank-credit",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 110,
        maxWidth: 110,
        template: "statusTemplate",
        nature: "creditStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        template: "dateYmdTemplate",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        allowFiltering: true,
        allowSorting: true,
        allowGrouping: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "description",
        headerText: "Objet",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        allowGrouping: false,
        visible: true,
        filter: { type: "Menu", operator: "contains" },
        clipMode: "EllipsisWithTooltip",
        template: "stripTagsTemplate",
      },
      {
        type: "string",
        field: "creditTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "totalHt",
        allowAggregate: true,
        headerText: "Total HT",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
      },
      {
        type: "number",
        field: "documentTva",
        headerText: "Total TVA",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: false,
        template: "tvaTemplate",
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        allowAggregate: true,
        field: "totalTtc",
        headerText: "Total TTC",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
      },
      {
        type: "number",
        field: "remainingToPayTtc",
        headerText: "Restant dû TTC",
        allowAggregate: true,
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        showFooterSum: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingDocumentsListByAffair",
      "institutionSelected",
      "affair",
      "userParametersList",
    ]),
    document: {
      get() {
        return this.$store.getters.document;
      },
      set(value) {
        return this.$store.commit("SET_DOCUMENT", value);
      },
    },
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "sListByAffair"].filter((elem) =>
          elem[this.name + "Tags"].find((tag) =>
            this.tagsSelected.includes(tag.id)
          )
        );
      } else {
        return this.$store.getters[this.name + "sListByAffair"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByAffair"].filter(
              (elem) =>
                elem[this.name + "sListByAffair"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                )
            );
          } else {
            return this.$store.getters[this.name + "sListByAffair"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[this.name + "sListByAffair"].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive)
            );
          } else {
            return this.$store.getters[this.name + "sListByAffair"].filter(
              (elem) =>
                statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  async created() {
    let filter = this.userParametersList.find(
      (el) => el.key == "affairCreditsFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
    this.getCreditTags({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getCreditsByAffairId",
      "getCreditTags",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    activeTabChange(tab) {
      this.activeTab = tab;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getCreditsByAffairId({
          affairId: this.affairId,
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    toggleModal() {
      this.selectInstitution = this.institutionSelected;
      this.$refs["modal-new-credit"].toggle("#toggle-btn-new-credit");
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.toggleModalNewCredit(true);
    },
    toggleModalNewCredit(bool = false) {
      this.activeTab = "blank-credit";
      this.activePopupCredit = bool;
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },
    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarDocumentContent"].getDocumentDetails(
        event.rowData.id,
        event.rowData.nature
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
