<template lang="pug">
ejs-accumulationchart#piechartmissionsbyaffaircontainer(v-if="loaded" ref='piechartmissionsbyaffaircontainer' style='display:block' align='center' :title='title' :legendSettings='legendSettings' :tooltip='tooltip' enableSmartLables='true' :enableAnimation='enableAnimation' :enableBorderOnMouseMove='false' :palettes="palettes")
    e-accumulation-series-collection
        e-accumulation-series(:dataSource='seriesData' :startAngle='startAngle' :explodeOffset='explodeOffset' :explodeIndex='explodeIndex' :radius='radius' xName='label' yName='totalAmount' :dataLabel='dataLabel' name='label' innerRadius='0%' explode='true')  
</template>

<script>
import { mapGetters, mapActions } from "vuex";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import { Browser } from "@syncfusion/ej2-base";
import {
  AccumulationTooltip,
  PieSeries,
  AccumulationDataLabel,
  AccumulationLegend,
} from "@syncfusion/ej2-vue-charts";

export default {
  components: {},
  props: {
    affairId: {
      default: null,
      required: true,
    },
    dateRange: {
      default: null,
      required: true,
    },
  },
  provide: {
    accumulationchart: [
      AccumulationTooltip,
      PieSeries,
      AccumulationDataLabel,
      AccumulationLegend,
    ],
  },
  data() {
    return {
      title: "Tableau de bord",
      name: "dashboard",
      loaded: false,

      seriesData: [],
      palettes: [
        "#0c3571",
        "#104494",
        "#114DA6",
        "#1355B9",
        "#155ECB",
        "#1767DE",
      ],
      dataLabel: {
        visible: true,
        position: "Outside",
        name: "label",
        font: {
          fontWeight: "600",
        },
        connectorStyle: { length: "20px", type: "Curve" },
      },
      radius: "70%",
      legendSettings: {
        visible: false,
      },
      tooltip: {
        enable: true,
        format: "<b>${point.x}</b><br><b>${point.y} €</b>",
        header: "",
      },
      enableSmartLabels: true,
      enableAnimation: true,
      startAngle: "35",
      explodeOffset: "10%",
      explodeIndex: 0,
      title: "",
    };
  },
  computed: {
    ...mapGetters([
      "affairCostingsListByAffair",
      "affairCostingFilters",
      "isLoadingAffairCostingsList",
    ]),
  },
  watch: {
    isLoadingAffairCostingsList: function (newVal, oldVal) {
      if (!newVal && oldVal) this.getSeriesData();
    },
  },
  created() {
    this.getSeriesData();
  },
  methods: {
    ...mapActions([]),
    getSeriesData() {
      let _this = this;
      this.loaded = false;
      this.seriesData = [];

      if (this.dateRange) {
        this.affairCostingsListByAffair
          .filter((el) => el.missionId !== null)
          .reduce(function (res, value) {
            if (!res[value.missionId]) {
              res[value.missionId] = {
                missionId: value.missionId,
                totalAmount: 0,
                label: value.label,
              };
              _this.seriesData.push(res[value.missionId]);
            }
            res[value.missionId].totalAmount +=
              value.amountUnit * value.valuatedQuantity;
            return res;
          }, {});
      }

      this.loaded = true;
    },
  },
};
</script>
<style>
#piechartmissionsbyaffaircontainer {
  height: 350px;
}
</style>
