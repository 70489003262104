<template lang="pug">
div.h-100.w-100
  .d-flex.flex-column
    #nav-sticky-horizontal.d-flex.justify-content-between.align-items-center.w-100.p-1
      ul.d-flex.space-between.w-100(style="height: 32px; overflow: hidden")
        li
          a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'general' ? 'current' : ''" @click="tabActive = 'general'")
            span Général
        li
          a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'details' ? 'current' : ''" @click="tabActive = 'details'")
            span Détails
        li
          a.h-100.p-0.mr-1.d-flex.align-center.justify-content-center.align-items-center.w-100(:class="tabActive == 'internalNote' ? 'current' : ''" @click="tabActive = 'internalNote'")
            span Notes internes
            feather-icon.align-middle.ml-50(v-if="affairForm.internalNote" icon='MessageCircleIcon')
      div.d-flex.align-items-center.ml-auto
          ejs-dropdownbutton(cssClass="e-outline mr-1" :select="selectParamerterAction" :items="parametersActionList" iconCss="e-drpdwnbtn-icons e-btn-sb-icons e-icons e-settings"  :isPrimary="true" )
          ejs-button( v-if='affairForm.id' cssClass="e-outline e-danger" :iconCss="'e-btn-sb-icons e-icons e-folder-open'" @click='archiveAffairLocal(affairForm.id, affairForm.name)')
          .e-btn-group.ml-1.e-lib.e-css
            ejs-button(v-on:click.native="saveAffair(true)" class="rounded" content="Enregistrer" :isPrimary="true" locale='fr' :iconCss="'e-btn-sb-icons e-icons e-save'" iconPosition="Right")
  .d-flex.flex-column.h-100.w-100(v-if='isLoadingAffair || isCreatingAffair || isUpdatingAffair')
      .text-center.flex-center
          .loading-bg-inner
            .loader
              .outer
              .middle
              .inner
          .mt-5
              br
              br
              br
              |   {{ isLoadingAffair ? 'Chargement des détails de l\'affaire...' : 'Enregistrement de l\'affaire...' }} 
  .d-flex.w-100.content-wrapper(v-else style="height: calc(100% - 61px); overflow-y: auto;")
      .d-flex.h-100.w-100(v-if="tabActive == 'general'")
          edit-affair-general(:affairId="affairId" :affairForm="affairForm")
      .d-flex.h-100.w-100(v-if="tabActive == 'details'")
          edit-affair-details(ref="detailsTab" :affairId="affairId" :affairForm="affairForm" @saveAffair="saveAffair")
      .d-flex.h-100.w-100(v-if="tabActive == 'internalNote'")
          edit-affair-notes(:affairId="affairId" :affairForm="affairForm")
</template>
<script>
import { mapGetters, mapActions } from "vuex";

import vSelect from "vue-select";

import {
  ValidationProvider,
  ValidationObserver,
  configure,
  localize,
} from "vee-validate";
import { required, url, email } from "@validations";
configure({
  generateMessage: localize("fr", {
    messages: {
      url: "Ce champ doit être une URL",
      required: "Ce champ est requis",
      email: "Ce champ doit être un email",
    },
  }),
});
localize("fr");

import EditAffairGeneral from "@/components/directory/affair/EditAffairGeneral";
import EditAffairDetails from "@/components/directory/affair/EditAffairDetails";
import EditAffairNotes from "@/components/directory/affair/EditAffairNotes";

export default {
  components: {
    vSelect,
    ValidationObserver,
    ValidationProvider,
    EditAffairGeneral,
    EditAffairDetails,
    EditAffairNotes,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
    dataLoaded: {
      default: true,
      required: false,
    },
  },
  data() {
    return {
      title: "Description",
      name: "affairCustomFields",
      tabActive: "general",

      parametersActionList: [
        {
          text: "Archiver cette affaire",
          iconCss: "e-btn-sb-icons e-icon-columns",
          type: "archive",
        },
      ],

      affairForm: {},
    };
  },
  computed: {
    ...mapGetters([
      "isLoadingAffair",
      "isCreatingAffair",
      "isUpdatingAffair",
      "affair",
      "isLoadingUser",
      "isLoadingCollaboratorsList",
      "collaboratorsList",
      "isLoadingCompany",
      "companiesList",
      "affairCategoriesTreeList",
      "affairsList",
    ]),
  },
  async created() {
    this.affairForm = this.affair;
    this.affairForm.companyId = this.affair.company?.id;
    this.affairForm.collaboratorIds =
      this.affairForm.collaborators && this.affairForm.collaborators.length > 0
        ? this.affairForm.collaborators.map((collaborator) => collaborator.id)
        : [];
    delete this.affairForm.collaborators;
    this.dataOrigine = JSON.stringify(this.affairForm);

    await this.getCustomFieldTemplates({
      type: 0,
    });
  },
  methods: {
    ...mapActions([
      "updateAffair",
      "getCustomFieldTemplates",
      "archiveAffairs",
    ]),
    selectParamerterAction(args) {
      if (args.item.type == "archive") {
        this.archiveAffairLocal(this.affairForm.id, this.affairForm.name);
      }
    },
    saveAffair(redirectTo = false) {
      if (this.tabActive == "details") {
        this.$refs.detailsTab.saveAffair();
      } else {
        const codeAlreadyExist = this.affairsList.find(
          (affair) =>
            affair.code === this.affairForm.code &&
            affair.id !== this.affairForm.id
        );
        if (this.affairForm.code === null || this.affairForm.code === "") {
          this.affairForm.code = null;
          this.$bvModal
            .msgBoxConfirm(
              "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
              {
                title:
                  "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
                size: "sm",
                okTitle: "Continuer",
                okVariant: "primary",
                cancelTitle: "Annuler",
                cancelVariant: "outline-danger",
                hideHeaderClose: true,
                centered: true,
              }
            )
            .then((value) => {
              if (value) {
                this.updateAffair({
                  affair: {
                    ...this.affairForm,
                    affairCategoryId: this.affairForm.affairCategoryId?.length
                      ? this.affairForm.affairCategoryId[0]
                      : null,
                  },
                  load: true,
                });

                if (redirectTo) {
                  this.$tabs.close({ to: "/affairs" });
                }
              }
            });
        } else if (codeAlreadyExist) {
          this.$bvModal.msgBoxConfirm(
            this.affairForm.code
              ? "Veuillez mettre un autre code affaire."
              : "Sans code affaire, il vous sera plus difficile d'analyser vos données.",
            {
              title: this.affairForm.code
                ? `Le code affaire ${this.affairForm.code} existe déjà.`
                : "Vous n'avez pas renseigné de code affaire. Êtes-vous sûr de vouloir continuer ?",
              size: "sm",
              okTitle: "D'accord",
              okVariant: "primary",
              cancelTitle: "Fermer",
              cancelVariant: "outline-danger",
              hideHeaderClose: true,
              centered: true,
            }
          );
        } else {
          this.updateAffair({
            affair: {
              ...this.affairForm,
              affairCategoryId: this.affairForm.affairCategoryId?.length
                ? this.affairForm.affairCategoryId[0]
                : null,
            },
            load: true,
          });
          if (redirectTo) {
            this.$tabs.close({ to: "/affairs" });
          }
        }
      }
    },
    archiveAffairLocal(id, label) {
      this.$bvModal
        .msgBoxConfirm(
          "Vous pourrez à tout moment restaurer cette affaire en consultant la liste de vos archives.",
          {
            title:
              "Êtes-vous sûr de vouloir archiver l'affaire \"" + label + '" ?',
            size: "sm",
            okVariant: "danger",
            okTitle: "Archiver",
            cancelTitle: "Annuler",
            cancelVariant: "outline-primary",
            hideHeaderClose: true,
            centered: true,
          }
        )
        .then((value) => {
          if (value) {
            this.archiveAffairs({ affairIds: [id], route: "affair" }).then(
              (res) => {
                this.$tabs.close({ to: "/affairs" });
                this.$bvToast.toast("L'affaire a été archivée avec succès.", {
                  title: "Affaire archivée",
                  variant: "success",
                  solid: true,
                });
              }
            );
          }
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.e-btn {
  display: flex;
  align-items: center;
  height: 32px;
}
@media screen and (max-width: 859px) {
  #nav-sticky-horizontal {
    flex-direction: column-reverse;
    padding-bottom: 0 !important;

    & > div {
      justify-content: flex-start !important;
      width: 100%;
      padding-bottom: 1rem;
    }

    & a span {
      max-width: 70px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .content-wrapper {
    height: calc(100% - 93px) !important;
  }
}
</style>
