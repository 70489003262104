<template lang="pug">
.p-0
  .content-loader-center.m-0.h-100(v-if='isLoadingAffair')
    .text-center.flex-center
      .loading-bg-inner
        .loader
          .outer
          .middle
          .inner
      .mt-5
        br
        br
        br
        | Chargement des détails de l'affaire...
  div.details-container(v-else)
      div.d-flex.content-scrollable-sticky.h-100.justify-content-start.w-100
        #nav-sticky-vertical.text-center.h-100(ref="navStickyVertical" style='background: #EEF1F6; width: 240px; min-width: 240px; max-width: 240px; position: relative; top: 0; overflow-y: auto; overflow-x: hidden; transition: all 0.5s ease-in-out;')
          .close-btn.d-flex.justify-content-end.pt-1.px-1
            span.material-icons-outlined(@click="closeMenuSidebar" style="cursor: pointer;")
              | chevron_left
          .head
            .d-flex.justify-content-between.px-1
              div
                h3.text-nowrap Affaire : {{affair.code}}
            .d-flex.justify-content-between.pb-1.px-1
              .d-flex
                //- Status(:statusId="document.status" :nature="document.nature")
                span.text-nowrap(style="white-space: break-spaces;") {{affair.name}}
              .end
          ul
            li.title
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary Dossier
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('affairDescription')" :class="tabActive == 'affairDescription' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | assignment
                span.pl-1.text-nowrap  Description
            li(v-if="checkFunctionUser(93, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('affairMissions')" :class="tabActive == 'affairMissions' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | list_alt
                span.pl-1.text-nowrap  Missions
            li(v-if="checkFunctionUser(42, 'all') && checkFunctionUser(43, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('ged')" :class="tabActive == 'ged' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | inbox
                span.pl-1.text-nowrap  Pièces/GED
            li
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('affairScheduler')" :class="tabActive == 'affairScheduler' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | event_note
                span.pl-1.text-nowrap  Planning
            li.title(v-if="checkFunctionUserByFunctionIdsAndActions({functionIds: [138, 119, 92], actions: ['all']})")
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary Chiffrage
            li(v-if="checkFunctionUser(138, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('dashboard')" :class="tabActive == 'dashboard' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | pie_chart
                span.pl-1.text-nowrap  Synthèse
            li(v-if="checkFunctionUser(119, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('affairCostings')" :class="tabActive == 'affairCostings' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | view_timeline
                span.pl-1.text-nowrap  Budget/Prévisionnel
            li(v-if="checkFunctionUser(92, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('affairReportDetails')" :class="tabActive == 'affairReportDetails' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | event_available 
                span.pl-1.text-nowrap  Reporting Détaillé
            li.title(v-if="checkFunctionUserByFunctionIdsAndActions({functionIds: [75, 108, 66, 51, 24, 30, 12, 22, 17], actions: ['all']})")
              vs-divider(color="#cccccc" class="m-0 mt-1 mb-50" position="left")
                span.text-primary Documents
            li(v-if="checkFunctionUser(75, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('quotes')" :class="tabActive == 'quotes' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | request_quote
                span.pl-1.text-nowrap  Devis
            li(v-if="checkFunctionUser(108, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('orderFormCustomers')" :class="tabActive == 'orderFormCustomers' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | checklist_rtl
                span.pl-1.text-nowrap  Commandes client
            li(v-if="checkFunctionUser(66, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoices')" :class="tabActive == 'invoices' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | description
                span.pl-1.text-nowrap  Factures
            li(v-if="checkFunctionUser(51, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('credits')" :class="tabActive == 'credits' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | indeterminate_check_box
                span.pl-1.text-nowrap  Avoirs
            li(v-if="checkFunctionUser(24, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('orderFormProvider')" :class="tabActive == 'orderFormProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | insert_drive_file
                span.pl-1.text-nowrap Commandes fournisseur
            li(v-if="checkFunctionUser(30, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('receiptFormProvider')" :class="tabActive == 'receiptFormProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | download
                span.pl-1.text-nowrap  Bons de réception
            li(v-if="checkFunctionUser(12, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('deliveryForm')" :class="tabActive == 'deliveryForm' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | upload
                span.pl-1.text-nowrap  Bons de sortie
            li(v-if="checkFunctionUser(22, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('invoicesProvider')" :class="tabActive == 'invoicesProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | description
                span.pl-1.text-nowrap  Factures fournisseur
            li(v-if="checkFunctionUser(17, 'all')")
              a.d-flex.align-center.align-content-center.align-items-center.w-100(@click="activeTab('creditsProvider')" :class="tabActive == 'creditsProvider' ? 'current' : ''")
                span.material-icons-outlined.text-primary
                  | indeterminate_check_box
                span.pl-1.text-nowrap  Avoirs fournisseur
        #content-wrapper.d-flex.flex-column.h-100(style="width: calc(100% - 240px)" ref="contentWrapper")
          .h-100.w-100
            AffairDashboard(v-if="tabActive=='dashboard'" :affairId='affair.id')
            AffairDescription(v-if="tabActive=='affairDescription'" :affairId='affair.id' :dataLoaded='dataLoaded')
            AffairMissions(v-if="tabActive=='affairMissions'" :affairId='affair.id')
            AffairScheduler(v-if="tabActive=='affairScheduler'" :affairId='affair.id')
            AffairCostings(v-if="tabActive=='affairCostings'" :affairId='affair.id')
            AffairReportDetails(v-if="tabActive=='affairReportDetails'" :affairId='affair.id')
            AffairQuotesList(v-if="tabActive=='quotes'" :affairId='affair.id')
            AffairOrderFormCustomersList(v-else-if="tabActive=='orderFormCustomers'" :affairId='affair.id')
            AffairInvoicesList(v-else-if="tabActive=='invoices'" :affairId='affair.id')
            AffairCreditsList(v-else-if="tabActive=='credits'" :affairId='affair.id')
            // <ChargesList v-else-if="tabActive=='charges'" :affairId="affair.id" />
            AffairOrderFormProviderList(v-else-if="tabActive=='orderFormProvider'" :companyId="affair.companyId" :affairId='affair.id')
            AffairReceiptFormProviderList(v-else-if="tabActive=='receiptFormProvider'" :affairId='affair.id' :companyId="affair.companyId")
            AffairDeliveryFormList(v-else-if="tabActive=='deliveryForm'" :affairId='affair.id')
            AffairInvoicesProviderList(v-else-if="tabActive=='invoicesProvider'" :affairId='affair.id')
            AffairCreditsProviderList(v-else-if="tabActive=='creditsProvider'" :affairId='affair.id')
            GedViewer(v-else-if="tabActive=='ged'" :fromModule="'affair'" :parentFolder='affair.folderId')


</template>
<script>
import { mapGetters, mapActions } from "vuex";
import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip,
} from "bootstrap-vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
require("dayjs/locale/fr");
dayjs.locale("fr");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import GedViewer from "@/components/ged/GedViewer";

import AffairDashboard from "@/components/directory/affair/AffairDashboard";
import AffairDescription from "@/components/directory/affair/AffairDescription";
import AffairMissions from "@/components/directory/affair/AffairMissions";
import AffairScheduler from "@/components/directory/affair/AffairScheduler";
import AffairReportDetails from "@/components/directory/affair/AffairReportDetails";
import AffairCostings from "@/components/directory/affair/AffairCostings";

import AffairQuotesList from "@/components/directory/affair/AffairQuotesList";
import AffairOrderFormCustomersList from "@/components/directory/affair/AffairOrderFormCustomersList";
import AffairInvoicesList from "@/components/directory/affair/AffairInvoicesList";
import AffairCreditsList from "@/components/directory/affair/AffairCreditsList";
import AffairOrderFormProviderList from "@/components/directory/affair/AffairOrderFormProviderList";
import AffairDeliveryFormList from "@/components/directory/affair/AffairDeliveryFormList";
import AffairReceiptFormProviderList from "@/components/directory/affair/AffairReceiptFormProviderList";
import AffairInvoicesProviderList from "@/components/directory/affair/AffairInvoicesProviderList";
import AffairCreditsProviderList from "@/components/directory/affair/AffairCreditsProviderList";
import {
  checkFunctionUser,
  checkFunctionUserByFunctionIdsAndActions,
} from "@/auth/utils.ts";
import { formatDate } from "@/types/api-orisis/library/FormatOperations.ts";

export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,

    AffairDashboard,
    AffairDescription,
    AffairMissions,
    AffairScheduler,
    AffairReportDetails,
    AffairCostings,

    AffairQuotesList,
    AffairOrderFormCustomersList,
    AffairInvoicesList,
    AffairCreditsList,
    AffairOrderFormProviderList,
    AffairDeliveryFormList,
    AffairReceiptFormProviderList,
    AffairInvoicesProviderList,
    AffairCreditsProviderList,
    GedViewer,
  },
  props: {
    id: {
      default: null,
    },
  },
  watch: {
    async $route(to, from) {
      this.$destroy();
    },
  },
  async mounted() {
    if (this.id !== "" && this.id !== 0 && this.affair.id !== this.id) {
      this.dataLoaded = false;
      await this.getAffairById({ affairId: this.id }).then(() => {
        setTimeout(() => {
          this.dataLoaded = true;
        }, 200);
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
  },
  data() {
    return {
      tabActive: "affairDescription",
      dataLoaded: true,
    };
  },
  computed: {
    ...mapGetters(["isLoadingAffair", "userParametersList"]),
    affair: {
      get() {
        return this.$store.getters.affair;
      },
      set(value) {
        return this.$store.commit("SET_AFFAIR", value);
      },
    },
  },
  methods: {
    ...mapActions(["getAffairById", "getCompanies", "getCollaborators"]),
    formatDate,
    checkFunctionUser,
    checkFunctionUserByFunctionIdsAndActions,
    activeTab(tab) {
      this.tabActive = tab;
    },
    checkModule(moduleId) {
      return true;
    },
    checkUserParameters(parameter) {
      if (
        this.userParametersList.find(
          (userParameter) => userParameter.key == "unauthorizedAccess"
        )
      ) {
        let affairUserParameters = JSON.parse(
          this.userParametersList.find(
            (userParameter) => userParameter.key == "unauthorizedAccess"
          ).value
        )?.affaire;
        if (affairUserParameters) {
          return !affairUserParameters.includes(parameter);
        } else {
          return true;
        }
      } else {
        return true;
      }
    },
    async editLease(id) {
      this.$router.push({
        name: "edit-affair",
        params: {
          id: id,
          title: "Edit : " + this.affair.name,
          tips: "Editer le bail : " + this.affair.name,
          routeOrigine: "affair",
        },
      });
    },
    closeMenuSidebar() {
      this.$refs.navStickyVertical.classList.toggle("sidebar-closed");
      this.$refs.contentWrapper.classList.toggle("sidebar-is-closed");
    },
  },
};
</script>

<style lang="scss">
.con-vs-tabs .con-slot-tabs {
  height: 100%;
  .vs-tabs--content {
    padding: 0px;
    height: 100%;
    > div {
      padding: 0px;
      height: 100%;
    }
  }
}

.sidebar-closed,
.sidebar-closed .title span {
  transition-delay: 0.5s;
  transition: all 0.5s ease-in-out;
}

.sidebar-closed .title span {
  font-size: 0;
}
.sidebar-closed .title .vs-divider {
  margin: 0 !important;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
}

.sidebar-closed .close-btn span {
  transform: rotate(180deg);
}

.sidebar-closed .close-btn {
  position: relative;
}

.head {
  padding-top: 1.2rem;
  & h3 {
    font-size: 1.2rem;
  }
}

.sidebar-closed .head {
  padding-top: 0;
}

.sidebar-closed .head,
.sidebar-closed .title {
  animation: fadeOut 0.5s ease-in-out forwards;
}

.sidebar-closed {
  width: 52px !important;
  min-width: 52px !important;
  max-width: 52px !important;
}

#content-wrapper {
  transition: all 0.5s ease-in-out;
}

#content-wrapper.sidebar-is-closed {
  width: calc(100% - 52px) !important;
}

#nav-sticky-vertical,
.title span,
.head,
.title,
.title .vs-divider {
  transition: all 0.5s ease-in-out;
}

@keyframes fadeOut {
  from {
    max-height: 100%;
    width: 100%;
    opacity: 1;
    visibility: visible;
  }
  to {
    max-height: 0%;
    width: 0%;
    opacity: 0;
    visibility: hidden;
  }
}
</style>
