<template lang="pug">
  .h-100.d-flex.flex-column
    HeaderGrid(:name="name" userFilter="affairOrderFormProviderFilters" :dateRangeDefault="dateRangeDefault" :title="title" :uid="uid" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :searchButton="searchButton" :tagguerButton="tagguerButton" :tvaMode="tvaMode" :selectedRows="selectedRows" @setDates="setDates" @updateTvaMode="updateTvaMode" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="selectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @refreshRow="refreshRow" :indexSearch="indexSearch")
    StatCards
        div.cursor-pointer.pl-1.col-card-list-stats(style='max-width: 250px;' @click="changeTab('all')")
          .card-list-stats(:class="tabActive == 'all' ? 'current' : ''")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.text-primary.mr-50 list
                span.card-list-stats-title-text TOUT
              .card-list-stats-title-badge.bg-primary.text-white {{ getCountDataByStatus("all") }}
            .card-sum
              | {{ getSumDataByStatus("all") }} 
              span {{ tvaMode }}
        div.cursor-pointer.pl-1.col-card-list-stats(v-for="(status, index) of statusList" :key="index" style='max-width: 250px;' @click="changeTab(status.value)")
          .card-list-stats(:class="[tabActive == status.value ? 'current' : '', 'border-'+status.class]")
            .card-list-stats-title
              div
                span.card-list-stats-title-badge.material-icons.mr-50(:class="'text-'+status.class") {{ status.icon }}
                span.card-list-stats-title-text {{ status.label }}
              .card-list-stats-title-badge.text-white(:class="'bg-'+status.class") {{ getCountDataByStatus(status.value) }}
            .card-sum
              | {{ getSumDataByStatus(status.value) }} 
              span {{ tvaMode }}
    .d-flex.w-100.h-100(style="overflow: hidden;")
      .text-center.flex-center.w-100(v-if='isLoadingOrderFormProvidersListByAffair || !headerLoaded')
        .loading-bg-inner
          .loader
            .outer
            .middle
            .inner
        .mt-5
          br
          br
          br
          | Chargement de la liste des bons de commande...
      sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :tagguerButton="tagguerButton" @rowClicked="onRowClicked" @setTagsSelected="setTagsSelected" @setSelectedRows="setSelectedRows")
    ejs-sidebar.default-sidebar.items-no-padding(v-show="isOpen" ref="sidebar" :type="type" :position="position" :showBackdrop="showBackdrop" :enablePersistence="enablePersistence"  :closeOnDocumentClick="closeOnDocumentClick" :isOpen="isOpen")
      sidebar-order-form-provider-content(ref="sidebarOrderFormProviderContent" @refreshGrid="refreshGrid" @closeSidebar="closeSidebar")
  </template>

<script>
import { mapGetters, mapActions } from "vuex";
import { orderFormProviderStatus } from "@/types/api-orisis/enums/enums";
import StatCards from "@/components/global/grid/StatCards.vue";

import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";

import SidebarOrderFormProviderContent from "@/components/purchase/order-form-provider/SidebarOrderFormProviderContent";
import { SidebarComponent } from "@syncfusion/ej2-vue-navigations";

export default {
  components: {
    SidebarOrderFormProviderContent,
    SyncGrid,
    StatCards,
    HeaderGrid,
    "ejs-sidebar": SidebarComponent,
  },
  data() {
    return {
      title: "Commandes fournisseur",
      indexSearch: 0,
      isOpen: false,
      position: "Right",
      type: "Push",
      enablePersistence: false,
      closeOnDocumentClick: true,
      showBackdrop: true,
      name: "orderForm",
      uid: "affairOrderFormProviders",
      statusList: orderFormProviderStatus.filter((el) => !el.notUse),
      headerData: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],
      headerLoaded: false,
      tabActive: "all",
      tagsSelected: [],
      selectedRows: [],
      documentSelected: {},

      tvaMode: "HT",
      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      searchButton: true,
      tagguerButton: true,
      searchPlaceholder: "Rechercher une commande fournisseur",
    };
  },
  beforeMount() {
    this.headerData = [
      {
        type: "checkbox",
        headerText: null,
        width: 30,
        maxWidth: 30,
        visible: true,
      },
      {
        type: "string",
        field: "status",
        headerText: "Statut",
        width: 100,
        maxWidth: 100,
        template: "statusTemplate",
        nature: "orderFormProviderStatus",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        filterTemplate: "statusFilterTemplate",
      },
      {
        type: "string",
        field: "documentReference",
        headerText: "Numéro",
        width: 160,
        maxWidth: 160,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "documentDate",
        headerText: "Date",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "number",
        field: "userId",
        filterTemplate: "userFilterTemplate",
        headerText: "Traité par",
        maxWidth: 150,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        valueAccessor: "user",
      },
      {
        type: "string",
        field: "companyProviderName",
        headerText: "Fournisseur",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "companyCustomerName",
        headerText: "Client",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "affairName",
        headerText: "Affaire",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "acknowledgmentNumber",
        headerText: "N° AR",
        width: "auto",
        minWidth: 200,
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        allowGrouping: false,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "estimatedDateReceipt",
        headerText: "Livraison estimée",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "date",
        format: "formatDateOptions",
        field: "receivedOn",
        headerText: "Reçue le",
        width: 95,
        maxWidth: 95,
        template: "dateYmdTemplate",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
      },
      {
        type: "string",
        field: "orderFormTags",
        headerText: "Tags",
        width: "auto",
        minWidth: 200,
        allowFiltering: false,
        allowSorting: false,
        showInColumnChooser: true,
        visible: true,
        clipMode: "EllipsisWithTooltip",
        template: "tagsTemplate",
        unavailableExport: true,
      },
      {
        type: "number",
        field: "documentTva",
        headerText: "Total TVA",
        maxWidth: 150,
        textAlign: "right",
        allowSorting: true,
        showInColumnChooser: true,
        visible: false,
        template: "tvaTemplate",
        format: "formatCurrency",
        allowGrouping: false,
        allowFiltering: true,
        allowAggregate: true,
      },
      {
        type: "number",
        field: "totalHt",
        headerText: "Total HT",
        maxWidth: 150,
        textAlign: "right",
        allowFiltering: true,
        allowSorting: true,
        showInColumnChooser: true,
        visible: true,
        format: "formatCurrency",
        allowGrouping: false,
        allowAggregate: true,
      },
    ];
  },
  computed: {
    ...mapGetters([
      "isLoadingOrderFormProvidersListByAffair",
      "institutionSelected",
      "workspaceSelected",
      "institutionsList",
       "userParametersList",
    ]),
    dataOrigine() {
      if (this.tagsSelected.length) {
        return this.$store.getters[this.name + "ProvidersListByAffair"].filter(
          (elem) =>
            elem[this.name + "Tags"].find((tag) =>
              this.tagsSelected.includes(tag.id)
            )
        );
      } else {
        return this.$store.getters[this.name + "ProvidersListByAffair"];
      }
    },
    dataFiltered() {
      if (this.statusList) {
        if (this.tabActive == "all") {
          if (this.tagsSelected.length) {
            return this.$store.getters[
              this.name + "ProvidersListByAffair"
            ].filter((elem) =>
              elem[this.name + "ProvidersListByAffair"].find((tag) =>
                this.tagsSelected.includes(tag.id)
              )
            );
          } else {
            return this.$store.getters[this.name + "ProvidersListByAffair"];
          }
        } else {
          let statusInfos = this.statusList.find(
            (elem) => elem.value == this.tabActive
          );
          if (this.tagsSelected.length) {
            return this.$store.getters[
              this.name + "ProvidersListByAffair"
            ].filter(
              (elem) =>
                elem[this.name + "Tags"].find((tag) =>
                  this.tagsSelected.includes(tag.id)
                ) &&
                (statusInfos.query
                  ? statusInfos.query.includes(elem.status)
                  : elem.status === this.tabActive)
            );
          } else {
            return this.$store.getters[
              this.name + "ProvidersListByAffair"
            ].filter((elem) =>
              statusInfos.query
                ? statusInfos.query.includes(elem.status)
                : elem.status === this.tabActive
            );
          }
        }
      } else {
        return [];
      }
    },
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
    companyId: {
      default: null,
      required: false,
    },
  },
  async created() {
    let filter = this.userParametersList.find(
      (el) => el.key == "affairOrderFormProviderFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(3, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
      });
    }
    await this.getCompanies({});
    await this.getCollaborators({});
    this.getOrderFormProviderTags({});
  },
  methods: {
    ...mapActions([
      "getCompanies",
      "getCollaborators",
      "getOrderForms",
      "getOrderFormProviderTags",
      "getOrderFormEmpty",
    ]),
    formatCurrency,
    setColumnsByObject,
    updateTvaMode(val) {
      this.tvaMode = val;
    },
    async changeTab(tab) {
      this.tabActive = tab;
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getOrderForms({
          affairId: this.affairId,
          dateFrom: dateRange.startDate,
          dateTo: dateRange.endDate,
          route: "affair",
        }).then(async (res) => {
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$store.commit(
        "SET_IS_GLOBAL_MESSAGE",
        "Création du bon de commande en cours..."
      );
      this.$store.commit("SET_IS_GLOBAL_CREATING", true);
      this.getOrderFormEmpty({
        affairId: this.affairId,
        providerId: null,
        customerId: this.companyId,
        storageLocationId: null,
      })
        .then((orderFormProvider) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
          this.$router.push({
            name: "new-order-form-provider",
            params: {
              id: orderFormProvider.id,
              title: "Nouveau bon de commande",
              tips: "Nouveau bon de commande",
              routeOrigine: "order-form-providers",
            },
          });
        })
        .catch((err) => {
          this.$store.commit("SET_IS_GLOBAL_CREATING", false);
        });
    },
    selectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
    getCountDataByStatus(status) {
      if (status == "all") {
        return this.dataOrigine.length;
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        return this.dataOrigine.filter((elem) =>
          statusInfos.query
            ? statusInfos.query.includes(elem.status)
            : elem.status === status
        ).length;
      }
    },
    getSumDataByStatus(status) {
      let sum = 0;
      if (status == "all") {
        this.dataOrigine.forEach((obj) => {
          if (this.tvaMode == "TTC") {
            sum += obj["totalTtc"];
          } else {
            sum += obj["totalHt"];
          }
        });
      } else {
        let statusInfos = this.statusList.find((elem) => elem.value == status);
        this.dataOrigine
          .filter((elem) =>
            statusInfos.query
              ? statusInfos.query.includes(elem.status)
              : elem.status === status
          )
          .forEach((obj) => {
            if (this.tvaMode == "TTC") {
              sum += obj["totalTtc"];
            } else {
              sum += obj["totalHt"];
            }
          });
      }
      return this.formatCurrency(sum);
    },
    setTagsSelected(val) {
      this.tagsSelected = val;
    },

    setSelectedRows(val) {
      this.selectedRows = val;
    },
    onRowClicked(event) {
      this.isOpen = true;
      this.$refs["sidebarOrderFormProviderContent"].getDocumentDetails(
        event.rowData.id,
        event.rowData.nature
      );
      this.$refs.sidebar.toggle();
    },
    refreshRow(val) {
      this.$refs.overviewgrid.$refs.overviewgrid.setRowData(val.id, val);
    },
    refreshGrid(res) {
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    closeSidebar() {
      this.$refs.sidebar.hide();
    },
  },
};
</script>
