<template lang="pug">
  //- TODO: Gérer le changement de planningView pour afficher les bons planning dans la modale
  //- TODO: Bien regarder si le planningViewId est bien passé dans les params de la config car ca pete les liaisons des events 
  div.d-flex.flex-column.m-0.h-100(v-if="!planningViewId")
    b-row.m-0.h-auto.flex-grow-0
      b-col.content-header(cols='12')
        b-form-group(label="Choisissez un planning pour cette affaire" label-for="type")
          v-select.my-1(v-model="selectedPlanningView" :options="planningViews" label="name" @input="loadSelectedPlanning")
            template(v-slot:no-options='')
              template  Aucun résultat trouvé
            li.border-bottom.p-1.py-50(slot='list-header')
              b-button.w-100.d-flex.justify-content-center.align-items-center(variant='primary' size='sm' @click="openCreatePlanningModal()")
                | + Créer un nouveau planning
                span.link_icon.material-icons-outlined.text-white.m-0.ml-50(style='font-size:13px')
        b-modal(ref="createPlanningModal" title="Créer une vue de planning pour cette affaire ?" hide-footer)
          .d-flex.justify-content-center.align-items-center
            b-button.mr-2(@click="createPlanningAutomatically" variant="primary") Créer automatiquement
            b-button(@click="createPlanningManually" variant="primary") Créer manuellement
  div.d-flex.flex-column.m-0.h-100(v-else)
    b-row.m-0.h-auto.flex-grow-0
      b-col.content-header(cols='12')
        .content-header-title.float-left.m-0.mr-1.d-flex.align-items-center
          v-select(v-model="selectedPlanningView" :options="planningViews" label="name" @input="loadSelectedPlanning")
        .d-flex.d-md-none.flex
        .d-none.d-md-flex.flex
          .position-relative.w-100.d-flex.align-items-center
            .d-flex.flex.mr-1
              DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" :userFilter="'planningFilters'" placeholder="Filtrer par période")
            ejs-multiselect(id="multiselecteventtype" :tagging="tagging" name="Collaborateurs" cssClass="e-fixe-height flex mr-1" :dataSource='eventTypesList' :itemTemplate="itemTemplate" :closePopupOnSelect="false" :fields="{text:'label',value:'id'}" placeholder='Filtrer par type' v-model="searchEventType" sortOrder="Ascending" filterType='Contains' @change="changeEventType")
              v-style(v-for="(eventType, eventTypeIndex) of eventTypesList" :key="eventTypeIndex")
                |.e-chips.bg_{{eventType.color.substring(1)}}{
                |    background-color: {{eventType.color}} !important;
                |    border:1px solid {{eventType.color}} !important;
                |}
                |.e-chips.bg_{{eventType.color.substring(1)}}:hover{
                |        background-color: {{eventType.color}}75 !important;
                |}
            .e-input-group.e-float-icon-left.d-none.d-lg-flex.flex.mr-1
              span.e-input-group-icon.e-icon-search
              .e-input-in-wrap
                input.e-input(@input="searchEventHandler" type='text' :placeholder='"Rechercher un évènement"' v-model='searchEvent')
            .content-header-actions
              div
                  .e-btn-group.e-lib.e-css.group-center
                    ejs-button(style="margin-right:2px" v-on:click.native="refreshPlanning" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }"  :iconCss="'e-btn-sb-icons e-icons e-refresh'" iconPosition="Right")
                    ejs-button(style="margin-right:2px" v-on:click.native="goTodayPlanning" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-month'" iconPosition="Right")
                    ejs-button(v-on:click.native="editPlanning" :isPrimary="true" locale='fr' :spinSettings="{ position: 'Right' }" :iconCss="'e-btn-sb-icons e-icons e-settings'" iconPosition="Right")
      b-col.content-header.d-block.d-md-none(cols='12' style="padding-top: 0 !important")
        .w-100
          .position-relative.w-100.d-flex.align-items-center.mb-50
            DateRanger(@setDates="setDates" :dateRangeDefault="dateRangeDefault" :userFilter="'planningFilters'" placeholder="Filtrer par période")
        .w-100
          .d-flex.flex
            .position-relative.w-100.d-flex.align-items-center
              ejs-multiselect(id="multiselecteventtypemobile" :tagging="tagging" name="Collaborateurs" cssClass="e-fixe-height mr-1" :dataSource='eventTypesList' :itemTemplate="itemTemplate" :closePopupOnSelect="false" :fields="{text:'label',value:'id'}" placeholder='Filtrer par type' v-model="searchEventType" sortOrder="Ascending" filterType='Contains' @change="changeEventType")
              v-style(v-for="(eventType, eventTypeIndex) of eventTypesList" :key="eventTypeIndex")
                |.e-chips.bg_{{eventType.color.substring(1)}}{
                |    background-color: {{eventType.color}} !important;
                |    border:1px solid {{eventType.color}} !important;
                |}
                |.e-chips.bg_{{eventType.color.substring(1)}}:hover{
                |        background-color: {{eventType.color}}75 !important;
                |}
              .e-input-group.e-float-icon-left.flex
                span.e-input-group-icon.e-icon-search
                .e-input-in-wrap
                  input.e-input(@input="searchEventHandler" type='text' :placeholder='"Rechercher un évènement"' v-model='searchEvent')
    b-row.m-0.h-100.flex-grow-1
      .p-0.w-100(style='margin-bottom: 0px !important')
        .page-container.h-100
          bryntum-scheduler(ref='scheduler' :key="planningView.id + '_' + key" v-bind='schedulerConfig' :listeners='listeners' v-if='loaded' class="h-100 flex-grow-1")
          task-editor(v-model='showEditor' :eventRecord='eventRecord' :eventStore='eventStore' :resourceId='resourceId' @close='onCloseEditor' :resourceTypeLine='resourceTypeLine')
    EditPlanningView(:key="planningView.id" ref="modalEditPlanningView" @planning-view-updated="onPlanningViewUpdated" @update-columns="handleColumnUpdate" :columns="schedulerConfig.columns" :planningViewId="planningViewId")
</template>

<script>
import EditPlanningView from "@/views/planning/settings/PopupCreatePlanningView.vue";
import { mapGetters, mapActions } from "vuex";
import { BryntumScheduler } from "@bryntum/schedulerpro-vue";
import {
  StringHelper,
  LocaleManager,
  DateHelper,
  TimeSpan,
  RecurringTimeSpan,
  RecurringTimeSpansMixin,
  Store,
  Tooltip,
  Popup,
} from "@bryntum/schedulerpro";
const { xss } = StringHelper;
import localeFr from "@/locales.Fr";
LocaleManager.registerLocale("Fr", {
  desc: "French",
  locale: localeFr,
});
LocaleManager.applyLocale("Fr");

import TaskEditor from "@/components/planning/TaskEditor";

class MyTimeRange extends RecurringTimeSpan(TimeSpan) {}
class MyTimeRangeStore extends RecurringTimeSpansMixin(Store) {
  static get defaultConfig() {
    return {
      // use our new MyTimeRange model
      modelClass: MyTimeRange,
      storeId: "timeRanges",
    };
  }
}

import vSelect from "vue-select";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { natureShortTranslate } from "@/types/api-orisis/library/TranslateOperations.ts";

import DateRanger from "@/components/global/date-ranger/DateRanger.vue";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

new Tooltip({
  forSelector: ".add",
  html: "Ajouter une ligne",
  hideDelay: 100,
});

export default {
  name: "app",
  components: {
    BryntumScheduler,
    TaskEditor,
    vSelect,
    DateRanger,
    EditPlanningView,
  },
  props: {
    id: {
      default: 0,
    },
  },
  data() {
    return {
      planningViews: [],
      selectedPlanningView: null,
      planningViewId: null,
      resourceTypeLine: null,
      searchEvent: "",
      searchEventType: [],
      schedulerConfig: {
        useInitialAnimation: "slide-from-left",
        eventColor: "#0b3571",
        flex: "1 0 100%",

        rowHeight: 50,
        barMargin: 5,
        weekStartDay: 1,
        /* Setting the default values for the calendar. */
        snap: true,
        multiEventSelect: true,
        passStartEndParameters: true, // retour de l'api envoie une date de début ET une date de fin de l'évènement

        /* PARAMETRES D'AFFICHAGE DU PLANNING (TEMPORALITE ETC...) */
        minZoomLevel: 10,
        viewPreset: {
          base: "dayAndWeek",
          tickWidth: 40,
          timeResolution: {
            unit: "day",
            increment: 1,
          },
          headers: [
            {
              unit: "month",
              dateFormat: "MMMM YYYY",
              align: "start",
            },
            {
              unit: "week",
              renderer: function (start) {
                return (
                  "Semaine  <strong>" +
                  DateHelper.format(start, "WW") +
                  "</strong>"
                );
              },
            },
            {
              unit: "day",
              renderer: (start, end, headerConfig, index) => {
                headerConfig.headerCellCls = "text-center";
                return (
                  DateHelper.format(start, "dd") +
                  "<br><strong>" +
                  DateHelper.format(start, "DD") +
                  "</strong>"
                );
              },
            },
          ],
        },

        /* PARAMETRES D'AFFICHAGE DES COLLONNES DE GAUCHE (Nom des collabs etc...) */
        columns: [
          {
            text: "Catégorie",
            type: "tree",
            width: 100,
            field: "category",
            hidden: true,
          },
          {
            text: "Ligne",
            field: "name",
            hidden: false,
            ascending: true,
            autoWidth: true,
            htmlEncode: false,
            editor: false,
            sum: "count",
            summaryRenderer: ({ sum }) => StringHelper.xss`Total: ${sum}`,
            showEventCount: false,
            showMeta: (event) => StringHelper.xss`Sleeps ${event.length}`,
            // renderer({ record, value, size }) {
            //   if (record.typeLine == 1) {
            //     const image = record.image
            //       ? StringHelper.xss`<img class="profile-img" src="resources/images/${record.name.toLowerCase()}.jpg" />`
            //       : "";
            //     size.height = 60;
            //     return (
            //       StringHelper.xss`
            //             <div class = "info">
            //               <div class = "name">${record.name}</div>
            //               <div class = "add"><i class = "b-fa b-fa-plus"></i></div>
            //             </div>
            //         ` + image
            //     );
            //   } else {
            //     return StringHelper.xss`<div class="client-color ${record.color}"></div>${value}`;
            //   }
            // },
          },
          {
            text: "Groupe",
            field: "group",
            hidden: true,
            autoWidth: true,
            editor: false,
            description: "Groupe",
          },
          {
            text: "TypeLine",
            field: "typeLine",
            hidden: true,
            autoWidth: true,
            editor: false,
            description: "Type de la ligne",
          },
          {
            text: "Couleur",
            field: "color",
            hidden: true,
            autoWidth: true,
            description: "Couleur de la ligne",
          },
          {
            text: "Position",
            field: "position",
            hidden: true,
            autoWidth: true,
            editor: false,
            description: "Position de la ligne",
          },
          {
            text: "Nbre Evenements",
            description: "Nombre d'évènements planifiés sur la ligne",
            field: "eventTypes",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            align: "center",
            renderer({ record }) {
              if (record.resourceTypeLine === 3) {
                return "";
              }
              const events = record.events.map((event) => event.eventTypeId);
              return StringHelper.encodeHtml(events.length.toString());
            },
          },
          {
            text: "Jours<br>Planifiés",
            description: "Nombre de jours planifiés sur la ligne",
            field: "plannedDays",
            type: "number",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer({ record: resource }) {
              if (resource.resourceTypeLine === 3) {
                return "";
              }
              const duration = resource.events.reduce(
                (total, task) => total + task.duration,
                0
              );
              return this.defaultRenderer({ value: duration });
            },
          },
          {
            text: "Jours<br>Disponibles",
            description:
              "Nombre de jours disponibles sur la ligne pour la période choisie",
            type: "number",
            field: "availableDays",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              if (resource.resourceTypeLine === 3) {
                cellElement.innerHTML = "";
                return;
              }
              const startDate = this.filterDateRange?.startDate;
              const endDate = this.filterDateRange?.endDate;

              if (!startDate || !endDate) {
                cellElement.innerHTML = "";
                return;
              }

              const totalWorkingDays = this.calculateWorkingDays(
                startDate,
                endDate
              );

              const plannedDays = resource.events.reduce((total, event) => {
                if (event.eventDetails && event.eventDetails.length > 0) {
                  const eventDays = event.eventDetails.reduce(
                    (subTotal, detail) => {
                      const detailStart = new Date(detail.startDate);
                      const detailEnd = new Date(detail.endDate);
                      return (
                        subTotal +
                        this.calculateWorkingDays(detailStart, detailEnd)
                      );
                    },
                    0
                  );
                  return total + eventDays;
                } else {
                  const eventStart = new Date(event.startDate);
                  const eventEnd = new Date(event.endDate);
                  return (
                    total + this.calculateWorkingDays(eventStart, eventEnd)
                  );
                }
              }, 0);

              const availableDays = totalWorkingDays - plannedDays;

              if (availableDays < 0) {
                cellElement.classList.add("negative-value");
              } else {
                cellElement.classList.remove("negative-value");
              }

              cellElement.innerHTML = `${availableDays} j`;
            },
          },
          {
            text: "Heures<br>Planifiées",
            description:
              "Nombre d'heures planifiées sur la ligne pour la période choisie",
            type: "number",
            field: "plannedHours",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              const uniqueEvents = new Set(
                resource.events.map((event) => event.id)
              );

              const plannedHours = Array.from(uniqueEvents).reduce(
                (total, eventId) => {
                  const event = resource.events.find((e) => e.id === eventId);
                  if (event) {
                    if (event.eventDetails && event.eventDetails.length > 0) {
                      const eventHours = event.eventDetails.reduce(
                        (subTotal, detail) => {
                          return subTotal + detail.Duration;
                        },
                        0
                      );
                      return total + eventHours;
                    } else {
                      if (
                        dayjs(event.endDate).diff(
                          dayjs(event.startDate),
                          "days"
                        ) < 1
                      ) {
                        return total + event.Duration;
                      } else {
                        return (
                          total +
                          (event.HoursDay
                            ? event.HoursDay *
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "days"
                              )
                            : 7 *
                              dayjs(event.endDate).diff(
                                dayjs(event.startDate),
                                "days"
                              ))
                        );
                      }
                    }
                  }
                  return total;
                },
                0
              );

              cellElement.innerHTML = `${plannedHours.toFixed(2)} h`;
            },
          },
          {
            text: "Heures<br>disponibles",
            description:
              "Nombre d'heures disponibles sur la ligne pour la période choisie",
            type: "number",
            field: "availableHours",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              const startDate = this.filterDateRange?.startDate;
              const endDate = this.filterDateRange?.endDate;

              if (!startDate || !endDate) {
                cellElement.innerHTML = "N/A";
                return;
              }

              // Calculer le nombre de jours ouvrés dans la période
              const totalWorkingDays = this.calculateWorkingDays(
                startDate,
                endDate
              );

              if (resource.data.CollaboratorId) {
                // Si CollaboratorId est présent, utiliser les heures disponibles du collaborateur
                const collaborator = this.collaboratorsList.find(
                  (collab) => collab.id === resource.data.CollaboratorId
                );

                if (!collaborator) {
                  cellElement.innerHTML = "N/A";
                  return;
                }

                const hoursPerDay = collaborator.hoursByDay || 8;
                const totalAvailableHours = totalWorkingDays * hoursPerDay;

                const uniqueEvents = new Set(
                  resource.events.map((event) => event.id)
                );

                const plannedHours = Array.from(uniqueEvents).reduce(
                  (total, eventId) => {
                    const event = resource.events.find((e) => e.id === eventId);
                    if (event) {
                      if (event.eventDetails && event.eventDetails.length > 0) {
                        const eventHours = event.eventDetails.reduce(
                          (subTotal, detail) => subTotal + detail.Duration,
                          0
                        );
                        return total + eventHours;
                      } else {
                        const eventDuration = dayjs(event.endDate).diff(
                          dayjs(event.startDate),
                          "hours",
                          true
                        );
                        return total + Math.min(eventDuration, hoursPerDay);
                      }
                    }
                    return total;
                  },
                  0
                );

                const availableHours = totalAvailableHours - plannedHours;

                if (availableHours < 0) {
                  cellElement.classList.add("negative-value");
                } else {
                  cellElement.classList.remove("negative-value");
                }

                cellElement.innerHTML = `${availableHours.toFixed(2)} h`;
              } else {
                const hoursPerDay = 8;
                const totalAvailableHours = totalWorkingDays * hoursPerDay;

                cellElement.innerHTML = `${totalAvailableHours.toFixed(2)} h`;
              }
            },
          },
          {
            text: "Plan de charge<br>% en jours",
            description:
              "Pourcentage de jours planifiés sur la ligne pour la période choisie",
            type: "percent",
            field: "workingDays",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              const startDate = this.filterDateRange?.startDate;
              const endDate = this.filterDateRange?.endDate;

              if (!startDate || !endDate) {
                cellElement.innerHTML = "";
                return;
              }

              const totalWorkingDays = this.calculateWorkingDays(
                startDate,
                endDate
              );

              const plannedDays = resource.events.reduce((total, event) => {
                if (event.eventDetails && event.eventDetails.length > 0) {
                  const eventDays = event.eventDetails.reduce(
                    (subTotal, detail) => {
                      const detailStart = new Date(detail.startDate);
                      const detailEnd = new Date(detail.endDate);
                      return (
                        subTotal +
                        this.calculateWorkingDays(detailStart, detailEnd)
                      );
                    },
                    0
                  );
                  return total + eventDays;
                } else {
                  const eventStart = new Date(event.startDate);
                  const eventEnd = new Date(event.endDate);
                  return (
                    total + this.calculateWorkingDays(eventStart, eventEnd)
                  );
                }
              }, 0);

              const percentage = (plannedDays / totalWorkingDays) * 100;
              const cellClass =
                plannedDays > totalWorkingDays ? "over-limit" : "";
              cellElement.innerHTML = `
                <div class="progress-bar-container ${cellClass}">
                  <div class="progress-bar" style="width: ${percentage}%;">
                  </div>
                  <div class="progress-label">${percentage.toFixed(2)}%</div>
                  <div class="progress-text">${plannedDays} / ${totalWorkingDays}</div>
                </div>
              `;
            },
          },
          {
            text: "Montant disponible",
            description:
              "Montant disponible sur la ligne pour la période choisie",
            type: "number",
            field: "availableAmount",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              const startDate = this.filterDateRange?.startDate;
              const endDate = this.filterDateRange?.endDate;

              if (!startDate || !endDate) {
                cellElement.innerHTML = "N/A";
                return;
              }

              const collaborator = this.collaboratorsList.find(
                (collab) => collab.id === resource.data.CollaboratorId
              );

              if (!collaborator) {
                cellElement.innerHTML = "N/A";
                return;
              }

              const hoursPerDay = collaborator.hoursByDay || 8;
              const totalWorkingDays = this.calculateWorkingDays(
                startDate,
                endDate
              );
              const totalAvailableHours = totalWorkingDays * hoursPerDay;

              const uniqueEvents = new Set(
                resource.events.map((event) => event.id)
              );

              const plannedHours = Array.from(uniqueEvents).reduce(
                (total, eventId) => {
                  const event = resource.events.find((e) => e.id === eventId);
                  if (event) {
                    if (event.eventDetails && event.eventDetails.length > 0) {
                      const eventHours = event.eventDetails.reduce(
                        (subTotal, detail) => subTotal + detail.Duration,
                        0
                      );
                      return total + eventHours;
                    } else {
                      const eventDuration = dayjs(event.endDate).diff(
                        dayjs(event.startDate),
                        "hours",
                        true
                      );
                      return (
                        total +
                        (eventDuration < 24
                          ? event.Duration
                          : (event.HoursDay * eventDuration) / 24)
                      );
                    }
                  }
                  return total;
                },
                0
              );

              const availableHours = totalAvailableHours - plannedHours;
              const hourlyRate = collaborator.grossDailyCost / hoursPerDay;
              const availableAmount = availableHours * hourlyRate;

              cellElement.classList.toggle(
                "negative-value",
                availableHours < 0
              );
              cellElement.innerHTML = `${availableAmount.toFixed(2)} €`;
            },
          },
          {
            text: "Montant prévu",
            description: "Montant prévu sur la ligne pour la période choisie",
            type: "number",
            field: "plannedAmount",
            editor: false,
            sortable: false,
            groupable: false,
            hidden: true,
            filterable: false,
            searchable: false,
            sortable: false,
            htmlEncodeHeaderText: false,
            align: "center",
            renderer: ({ record: resource, cellElement }) => {
              const startDate = this.filterDateRange?.startDate;
              const endDate = this.filterDateRange?.endDate;

              if (!startDate || !endDate) {
                cellElement.innerHTML = "N/A";
                return;
              }

              const collaborator = this.collaboratorsList.find(
                (collab) => collab.id === resource.data.CollaboratorId
              );

              if (!collaborator) {
                cellElement.innerHTML = "N/A";
                return;
              }

              const hoursPerDay = collaborator.hoursByDay || 8;
              const grossDailyCost = collaborator.grossDailyCost;

              if (grossDailyCost === undefined || grossDailyCost === null) {
                cellElement.innerHTML = "N/A";
                return;
              }

              const plannedHours = resource.events.reduce((total, event) => {
                let eventStart = dayjs(event.startDate);
                let eventEnd = dayjs(event.endDate);

                if (eventStart.isBefore(startDate)) {
                  eventStart = dayjs(startDate);
                }
                if (eventEnd.isAfter(endDate)) {
                  eventEnd = dayjs(endDate);
                }

                const eventDuration = eventEnd.diff(eventStart, "hours", true);

                if (eventDuration <= 24) {
                  return total + Math.min(eventDuration, hoursPerDay);
                } else {
                  const workingDays = this.calculateWorkingDays(
                    eventStart,
                    eventEnd
                  );
                  return total + workingDays * hoursPerDay;
                }
              }, 0);

              const plannedAmount =
                (plannedHours / hoursPerDay) * grossDailyCost;

              cellElement.innerHTML = `${Math.max(0, plannedAmount).toFixed(
                2
              )} €`;
            },
          },
        ],
        // listeners : {listeners},
      },
      itemTemplate:
        "<span id:'${id}' class='e-chips text-white' style='padding: 2px 6px 2px 8px; border-radius: 4px; background-color: ${color};'>${label}</span>",
      listeners: {
        cellClick({ record, event }) {
          if (event.target.closest(".add")) {
            record.appendChild({
              name: "New client",
            });
          }
        },
        cellDblClick({ record, cellElement, column }) {
          // Show a custom editor when dbl clicking a client cell
          if (column.field === "name" && record.isLeaf) {
            new Popup({
              autoShow: true,
              autoClose: true,
              closeAction: "destroy",
              scrollAction: "realign",
              forElement: cellElement,
              anchor: true,
              width: "20em",
              cls: "client-editor",
              items: [
                {
                  type: "text",
                  name: "name",
                  label: "Client",
                  labelWidth: "4em",
                  value: record.name,
                  onChange: ({ value }) => {
                    record.name = value;
                  },
                },
              ],
            });
          }
        },
        beforeEventEdit: this.beforeEventEditHandler,
        beforePaste: this.beforePasteHandler,
        beforeEventDrag: this.beforeEventDragHandler,
        beforeEventResize: this.beforeEventResizeHandler,
        beforeEventResizeFinalize: this.beforeEventResizeFinalizeHandler,
        beforeEventDropFinalize: this.beforeEventDropFinalizeHandler,
        eventResizeEnd: this.eventResizeEnd,
        afterEventEdit: this.afterEventEditHandler,
        toggle: this.toggleColumns,
        prio: 1,
      },
      eventsStore: {
        fields: [
          {
            name: "id",
            dataSource: "Id",
          },
          {
            name: "name",
            dataSource: "Name",
          },
          {
            name: "startDate",
            dataSource: "StartDate",
            type: "date",
          },
          {
            name: "endDate",
            dataSource: "EndDate",
            type: "date",
          },
          {
            name: "eventDetails",
            dataSource: "EventDetails",
          },
          {
            name: "PlanningLineId",
            dataSource: "PlanningLineId",
            convert: (v) => Number(v),
          },

          {
            name: "EventTypeId",
            dataSource: "EventTypeId",
          },
          {
            name: "AffairId",
            dataSource: "AffairId",
          },
          {
            name: "CompanyId",
            dataSource: "CompanyId",
          },
          {
            name: "IsHidden",
            dataSource: "IsHidden",
          },
          {
            name: "IsLocked",
            dataSource: "IsLocked",
          },
          {
            name: "HoursDay",
            dataSource: "HoursDay",
          },
          {
            name: "Comments",
            dataSource: "Comments",
          },
          {
            name: "style",
            dataSource: "Cls",
          },
          {
            name: "cls",
            dataSource: "Cls",
          },
        ],
      },
      resourcesStore: {
        id: "resourcePlanning",
        fields: [
          {
            name: "id",
            dataSource: "Id",
          },
          {
            name: "name",
            dataSource: "Name",
          },
          {
            name: "subName",
            dataSource: "SubName",
          },
          {
            name: "group",
            dataSource: "Group",
          },
          {
            name: "color",
            dataSource: "Color",
          },
          {
            name: "position",
            dataSource: "Position",
          },
          {
            name: "typeLine",
            dataSource: "TypeLine",
          },
        ],
      },
      assignmentStore: {
        fields: [
          {
            name: "id",
            dataSource: "Id",
          },
          {
            name: "resourceId",
            dataSource: "ResourceId",
          },
          {
            name: "eventId",
            dataSource: "EventId",
          },
          {
            name: "resourceTypeLine",
            dataSource: "ResourceTypeLine",
          },
        ],
      },
      key: 1,
      loaded: false,
      showEditor: false,
      eventRecord: null,
      eventStore: null,
      resourceId: null,
      filterDateRange: {
        startDate: new Date(
          dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
        ),
        endDate: new Date(
          dayjs().subtract(3, "month").endOf("month").format("YYYY-MM-DD")
        ),
      },
      dateRangeDefault: [
        new Date(
          dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD")
        ),
        new Date(dayjs().add(3, "month").endOf("month").format("YYYY-MM-DD")),
      ],
    };
  },
  computed: {
    ...mapGetters([
      "planningView",
      "isLoadingEventTypesList",
      "eventTypesList",
      "companiesList",
      "affairsList",
      "publicHolidayList",
      "collaboratorsList",
      "connectedUser",
      "usersList",
      "userParametersList",
      "moduleParametersList",
      "missionsList",
      "connectedUser",
      "collaborator",
    ]),
    planningSettingsSlot() {
      return this.moduleParametersList.find((el) => el.key == "slots")
        ? JSON.parse(
            this.moduleParametersList.find((el) => el.key == "slots").value
          )
        : [
            {
              shortLabel: "J",
              label: "Jour",
            },
          ];
    },
  },
  beforeCreate() {
    this.loaded = false;
  },
  async created() {
    await this.getModuleParameters({});
    await this.getPublicHolidays({});
    await this.loadPlanningViews();
    await this.getPlanningViewById({
      // Appeler le 103 en mode dev pour tester
      // planningViewId: 103,
      planningViewId: this.planningViewId,
    });
    await this.getPlanningLineCollaborators({});
    await this.getCollaborators({});
    await this.getEventTypes({});
    await this.getCompanies({});
    await this.getAffairs({});
    await this.getMissions({});
    await this.getUserParameterByKey({
      key: "planning_columns",
      userId: this.connectedUser.id,
    }).then((res) => {
      if (res.value) {
        const userColumns = JSON.parse(res.value);
        userColumns.forEach((userColumn) => {
          const column = this.schedulerConfig.columns.find(
            (col) => col.field === userColumn.field
          );
          if (column) {
            column.hidden = userColumn.hidden;
          }
        });
      }
    });

    let filter = this.userParametersList.find(
      (el) => el.key == "planningFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs()
            .subtract(1, "month")
            .startOf("month")
            .format("YYYY-MM-DD"),
          endDate: dayjs().add(3, "month").endOf("month").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD"),
        endDate: dayjs().add(3, "month").endOf("month").format("YYYY-MM-DD"),
      });
    }
  },
  async activated() {
    x;
    this.searchEvent = "";
    this.searchEventType = [];
  },
  methods: {
    ...mapActions([
      "getPlanningLineCollaborators",
      "getEventTypes",
      "getCompanies",
      "getAffairs",
      "getPlanningViews",
      "getPlanningViewById",
      "getCollaborators",
      "getMissions",
      "getModuleParameters",
      "getPublicHolidays",
      "createOrUpdateUserParameter",
      "getUserParameterByKey",
      "createPlanningViewAutomatically",
    ]),
    formatCurrency,
    natureShortTranslate,
    async loadPlanningViews() {
      try {
        this.planningViews = await this.getPlanningViews({}); // Récupère les vues de planning
      } catch (error) {
        console.error(
          "Erreur lors du chargement des vues de planning :",
          error
        );
      }
    },
    loadSelectedPlanning(planningView) {
      if (planningView && planningView.id) {
        this.planningViewId = planningView.id; // Met à jour la clé
        this.key++; // Incrémente la clé pour forcer le rechargement du composant
        this.refreshPlanning(); // Recharge le planning
        console.log("planningViewId mis à jour:", this.planningViewId);
        console.log("Scheduler Key:", this.planningViewId + "_" + this.key);
      }
    },
    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dayjs(dates.startDate).format("YYYY-MM-DD")),
          endDate: dates.endDate
            ? new Date(dayjs(dates.endDate).format("YYYY-MM-DD"))
            : new Date(dayjs().format("YYYY-MM-DD")),
        };
        this.filterDateRange = dateRange;
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        this.setPlanningParams(dateRange);
      }
    },
    calculateWorkingDays(startDate, endDate) {
      let count = 0;
      let currentDate = new Date(startDate);

      while (currentDate <= endDate) {
        const dayOfWeek = currentDate.getDay();
        if (dayOfWeek !== 0 && dayOfWeek !== 6) {
          count++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      return count;
    },
    setPlanningParams(dateRange) {
      let _this = this;
      this.loaded = true;

      this.schedulerConfig.startDate = dayjs(dateRange.startDate).toDate();
      this.schedulerConfig.endDate = dayjs(dateRange.endDate).toDate();

      this.schedulerConfig.crudManager = {
        delayCalculation: true,

        // Définition des datas
        timeRangeStore: new MyTimeRangeStore({
          data: [
            {
              id: 0,
              name: "",
              recurrenceRule: "FREQ=WEEKLY",
              startDate: "2000-01-01",
              duration: 2,
              durationUnit: "day",
              cls: "b-cols-weekend",
            },
            {
              id: 1,
              name: "",
              startDate: dayjs().format("YYYY-MM-DD"),
              duration: 1,
              durationUnit: "day",
              cls: "b-cols-today",
            },
            ...this.publicHolidayList,
          ],
        }),
        resourceStore: _this.resourcesStore,
        eventStore: _this.eventsStore,
        assignmentStore: _this.assignmentStore,

        // Requêtes au backend
        autoLoad: true,
        autoSync: true,
        writeAllFields: true,
        // autoSyncTimeout: 500,
        validateResponse: true,
        transport: {
          load: {
            url: `${process.env.VUE_APP_API_ORISIS}Plannings/SchedulerCrud/Load`,
            paramName: "q",
            credentials: "omit",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
            params: {
              // Appeler le 103 en mode dev pour tester
              // planningViewId: 103,
              planningViewId: _this.planningViewId,
              startDate: new Date(dateRange.startDate).toISOString(),
              endDate: new Date(dateRange.endDate).toISOString(),
            },
          },
          sync: {
            url: `${process.env.VUE_APP_API_ORISIS}Plannings/SchedulerCrud/Sync`,
            credentials: "omit",
            headers: {
              Authorization: `Bearer ${localStorage.getItem("userToken")}`,
            },
          },
        },
        onRequestFail: (event) => {
          const { requestType, response } = event,
            serverMessage = response && response.message,
            exceptionText = `Action "${requestType}" failed. ${
              serverMessage ? ` Server response : ${serverMessage}` : ""
            }`;
          console.error(exceptionText);
        },
      };

      this.schedulerConfig.features = {
        group: {
          field: "typeLine",
          ascending: false,
        },
        sort: "name",
        summary: {
          renderer: ({ events }) => {
            let result;
            result = events.length;
            return StringHelper.xss`${result}`;
          },
        },
        stripe: true,
        resourceTimeRanges: true,
        dependencies: false,
        scheduleContext: true,
        eventCopyPaste: {
          copyPasteAction: "clone",
        },
        filterBar: true,
        // resourceNonWorkingTime: true,

        nonWorkingTime: {
          highlightWeekends: true,
        },

        eventTooltip: {
          cls: "b-tooltip-custom",
          template: (data) => {
            let userEvent = this.usersList.find(
              (el) => el.id == data.eventRecord.UpdatedBy
            );
            let uniteHebdo = "jour";
            let company = "";
            let affair = "";
            let mission = "";
            let hours = "";
            let collaborator = "";

            if (
              data.eventRecord.IsHidden == true &&
              data.eventRecord.UpdatedBy !== this.connectedUser.id
            ) {
              return (
                '<dl class="mb-0 pb-0"><dt>Évènement privé de : ' +
                userEvent.firstName +
                " " +
                userEvent.lastName +
                "</dt></dl>"
              );
            } else {
              if (
                data.eventRecord.EventTypeId &&
                data.eventRecord.EventTypeId > 0 &&
                _this.eventTypesList.find(
                  (elem) => elem.id == data.eventRecord.EventTypeId
                )
              ) {
                if (
                  _this.eventTypesList
                    .find((elem) => elem.id == data.eventRecord.EventTypeId)
                    ?.label.includes("nuit")
                ) {
                  uniteHebdo = "nuit";
                }
              }
              if (
                data.eventRecord.CompanyId &&
                data.eventRecord.CompanyId > 0 &&
                _this.companiesList.find(
                  (elem) => elem.id == data.eventRecord.CompanyId
                )
              ) {
                company =
                  '<dt style="margin-top:5px"><span style="font-weight:300">Client : </span>' +
                  _this.companiesList.find(
                    (elem) => elem.id == data.eventRecord.CompanyId
                  )?.name +
                  "</dt>";
              }
              if (data.eventRecord.AffairId && data.eventRecord.AffairId > 0) {
                affair =
                  '<dt style="margin-top:5px"><span style="font-weight:300">Affaire : </span>' +
                  _this.affairsList.find(
                    (elem) => elem.id == data.eventRecord.AffairId
                  )?.displayLabel +
                  "</dt>";
              }
              if (
                data.eventRecord.MissionId &&
                data.eventRecord.MissionId > 0
              ) {
                mission =
                  '<dt style="margin-top:5px"><span style="font-weight:300">Mission : </span>' +
                  _this.missionsList.find(
                    (elem) => elem.id == data.eventRecord.MissionId
                  )?.label +
                  "</dt>";
              }
              if (
                data.eventRecord.CollaboratorIds &&
                data.eventRecord.CollaboratorIds.length > 0
              ) {
                collaborator =
                  '<dt style="margin-top:5px"><span style="font-weight:300">Collaborateur : </span>' +
                  _this.collaboratorsList.find(
                    (elem) => elem.id == data.eventRecord.CollaboratorIds[0]
                  )?.firstName +
                  " " +
                  _this.collaboratorsList.find(
                    (elem) => elem.id == data.eventRecord.CollaboratorIds[0]
                  )?.lastName +
                  "</dt>";
              }
              if (data.eventRecord.HoursDay && data.eventRecord.HoursDay > 0) {
                hours = " / " + data.eventRecord.HoursDay + "h/" + uniteHebdo;
              }
              return `<dl class="mb-0 pb-0">
                        <dt>${
                          _this.eventTypesList.find(
                            (elem) => elem.id == data.eventRecord.EventTypeId
                          )?.label
                        }${hours}</dt>
                        ${company}
                        ${affair}
                        ${mission}
                        ${collaborator}
                        ${
                          data.eventRecord.get("Comments")
                            ? `<dt style="margin-top:5px"><span style="font-weight:300">Détails : </span><br>${data.eventRecord.Comments}</dt>`
                            : ""
                        }
                      </dl>`;
            }
          },
        },
        stripe: true,
        // timeRanges: true,
        timeRanges: {
          showCurrentTimeLine: true,
          showHeaderElements: true,
        },
      };

      this.schedulerConfig.listeners = {
        beforeEventEdit: this.beforeEventEditHandler,
        beforePaste: this.beforePasteHandler,
        beforeEventDrag: this.beforeEventDragHandler,
        beforeEventResize: this.beforeEventResizeHandler,
        beforeEventResizeFinalize: this.beforeEventResizeFinalizeHandler,
        beforeEventDropFinalize: this.beforeEventDropFinalizeHandler,
        eventResizeEnd: this.eventResizeEnd,
        afterEventEdit: this.afterEventEditHandler,
      };
      this.schedulerConfig.eventRenderer = this.eventRenderer;
      this.key++;
      this.$nextTick(() => {
        this.loaded = true;
      });
    },
    beforePasteHandler(events) {
      const scheduler = this.$refs.scheduler.instance;
      if (
        typeof events.resourceRecord.PlanningLineId == "undefined" ||
        !events.resourceRecord.PlanningLineId ||
        events.resourceRecord.typeLine == 3 ||
        events.resourceRecord.typeLine == 4
      ) {
        // Vous ne pouvez pas coller un évènement sur cette ligne
        return false;
      } else {
        if (events.isCut) {
          return true;
        } else {
          events.records.forEach((record) => {
            const selectedFacilities = [events.resourceRecord.id];
            const selectedResourceIds = [...selectedFacilities];
            var newRecords = scheduler.eventStore.add({
              Cls: record.get("Cls"),
              StartDate: events.date,
              AffairId:
                record.get("AffairId") == 0 ? null : record.get("AffairId"),
              MissionId:
                record.get("MissionId") == 0 ? null : record.get("MissionId"),
              CompanyId:
                record.get("CompanyId") == 0 ? null : record.get("CompanyId"),
              Comments:
                record.get("Comments") == 0 ? null : record.get("Comments"),
              EventTypeId: record.get("EventTypeId"),
              HoursDay: record.get("HoursDay"),
              PlanningLineId: !record.get("PlanningLineId")
                ? 0
                : record.get("PlanningLineId"),
              Name: record.get("Name"),
              IsHidden: record.get("IsHidden"),
              IsLocked: record.get("IsLocked"),
              allDay: record.get("allDay"),
              constraintDate: record.get("constraintDate"),
              constraintType: record.get("constraintType"),
              direction: record.get("direction"),
              duration: record.get("duration"),
              durationUnit: record.get("durationUnit"),
              exceptionDates: record.get("exceptionDates"),
              inactive: record.get("inactive"),
              manuallyScheduled: record.get("manuallyScheduled"),
              percentDone: record.get("percentDone"),
              postamble: record.get("postamble"),
              preamble: record.get("preamble"),
              UpdatedBy: this.connectedUser.id,
            });

            newRecords[0].assign(selectedResourceIds);
            return false;
          });
        }
      }
      return false;
    },
    beforeEventSave(event) {},
    beforeEventEditHandler(event) {
      if (
        (event.eventRecord.IsLocked == true ||
          event.eventRecord.IsHidden == true) &&
        event.eventRecord.UpdatedBy !== this.connectedUser.id
      ) {
        return false;
      } else {
        this.openEditor(event);
      }
      return false;
    },
    toggleFilterBar() {
      const filterBar = document.getElementsByClassName("header-wrapper")[0];
      const currentHeight = filterBar.clientHeight;

      const isOpen = currentHeight > 55;
      const newHeight = isOpen ? "55px" : "250px"; // Vous pouvez ajuster la valeur de la nouvelle hauteur selon vos besoins

      filterBar.style.height = newHeight;
      filterBar.style.minHeight = newHeight;
    },
    beforeEventDragHandler(event) {
      if (
        (event.eventRecord.IsLocked == true ||
          event.eventRecord.IsHidden == true) &&
        event.eventRecord.UpdatedBy !== this.connectedUser.id
      ) {
        return false;
      } else {
        return true;
      }
    },
    afterEventEditHandler(args) {},
    toggleColumns(args) {
      this.schedulerConfig.columns.find(
        (column) => column.field === args.item.column.field
      ).hidden = !this.schedulerConfig.columns.find(
        (column) => column.field === args.item.column.field
      ).hidden;

      this.createOrUpdateUserParameter({
        label: "Planning Columns",
        key: "planning_columns",
        value: JSON.stringify(this.schedulerConfig.columns),
        userId: this.connectedUser.id,
      });
    },
    eventResizeEnd(event) {},
    beforeEventDropFinalizeHandler(event) {
      this.updateEventDetails(
        event.context,
        event.context.startDate,
        event.context.endDate,
        true
      );
    },
    beforeEventResizeFinalizeHandler(event) {
      this.updateEventDetails(
        event.context,
        event.context.startDate,
        event.context.endDate,
        false
      );
    },
    beforeEventResizeHandler(event) {
      if (
        (event.eventRecord.IsLocked == true ||
          event.eventRecord.IsHidden == true) &&
        event.eventRecord.UpdatedBy !== this.connectedUser.id
      ) {
        return false;
      } else {
        return true;
      }
    },
    updateEventDetails(context, newStartDate, newEndDate, dragged = false) {
      const obj = context.eventRecord;
      const eventDetails = obj.eventDetails;

      let start = dayjs(dayjs(newStartDate).format("YYYY-MM-DD"));
      const end = dayjs(dayjs(newEndDate).format("YYYY-MM-DD"));

      if (start.isAfter(end)) {
        throw new Error(
          "La date de début doit être avant ou égale à la date de fin"
        );
      }

      const updatedEventDetails = [];
      if (dragged && obj.eventDetails && obj.eventDetails.length > 0) {
        const diff = dayjs(dayjs(newStartDate).format("YYYY-MM-DD")).diff(
          dayjs(dayjs(context.origStart).format("YYYY-MM-DD")),
          "days",
          true
        );
        obj.eventDetails.forEach((eventDetail) => {
          updatedEventDetails.push({
            ...eventDetail,
            Date:
              diff > 0
                ? new Date(
                    dayjs(eventDetail.Date)
                      .add(Math.abs(diff), "day")
                      .format("YYYY-MM-DD")
                  )
                : new Date(
                    dayjs(eventDetail.Date)
                      .subtract(Math.abs(diff), "day")
                      .format("YYYY-MM-DD")
                  ),
            StartTimeSlot:
              diff > 0
                ? new Date(dayjs(eventDetail.Date).add(Math.abs(diff), "day"))
                : new Date(
                    dayjs(eventDetail.Date).subtract(Math.abs(diff), "day")
                  ),
            EndTimeSlot:
              diff > 0
                ? new Date(dayjs(eventDetail.Date).add(Math.abs(diff), "day"))
                : new Date(
                    dayjs(eventDetail.Date).subtract(Math.abs(diff), "day")
                  ),
          });
        });
      } else if (obj.eventDetails && obj.eventDetails.length > 0) {
        // Boucler entre les deux dates
        while (start.isBefore(end)) {
          let indexSlot = 0;
          for (const slot of this.planningSettingsSlot) {
            let event = eventDetails.find(
              (e) =>
                dayjs(new Date(e.Date)).format("YYYY-MM-DD") ==
                  start.format("YYYY-MM-DD") && e.ShortLabel === slot.shortLabel
            );
            if (!event) {
              event = {
                Date: new Date(start.format("YYYY-MM-DD")),
                StartTimeSlot: new Date(start),
                EndTimeSlot: new Date(start),
                ShortLabel: slot.shortLabel,
                Duration:
                  indexSlot == 0 && obj.HoursDay
                    ? obj.HoursDay
                    : obj.hoursDay
                    ? obj.hoursDay
                    : 0,
                Id: 0,
              };
            }
            updatedEventDetails.push(event);
            indexSlot++;
          }
          start = start.add(1, "day"); // Ajouter un jour à la date actuelle
        }
      }

      // Update the object
      obj.startDate = newStartDate;
      obj.endDate = newEndDate;
      obj.eventDetails = updatedEventDetails;

      return obj;
    },
    changeEventType(args) {
      const scheduler = this.$refs.scheduler.instance;
      if (this.searchEventType.length > 0) {
        scheduler.eventStore.filter({
          filters: (event) =>
            this.searchEventType.includes(event.data.EventTypeId),
          replace: true,
        });
      } else {
        scheduler.eventStore.clearFilters();
      }
    },
    searchEventHandler(value) {
      const scheduler = this.$refs.scheduler.instance;
      scheduler.eventStore.filter({
        filters: (event) => event.name.match(new RegExp(this.searchEvent, "i")),
        replace: true,
      });
    },
    openCreatePlanningModal() {
      this.$refs.createPlanningModal.show();
    },
    onPlanningViewUpdated(planningViewForm) {
      this.planningView = { ...planningViewForm };
      this.refreshPlanning();
    },
    async createPlanningAutomatically() {
      console.log("creation automatique");
      // TODO: Appeler l'endpoint api pour créer automatiquement le planning
      // TODO: Récupérer l'id de l'affaire et le lier à la planning view
      await this.createPlanningViewAutomatically({
        planningView: {
          name: "Planning automatique",
          linePositions: null,
          description: null,
          isActive: true,
          date: new Date(),
          dateRange: {
            startDate: dayjs().startOf("month").format("YYYY-MM-DD"),
            endDate: dayjs().endOf("month").format("YYYY-MM-DD"),
          },
          isPublicHoliday: false,
          isCurrentDay: false,
          isDefault: false,
          readOnly: false,
        },
      });
    },
    createPlanningManually() {
      console.log("creation manuelle");
      this.$router.push({ path: "/planning/settings/new-planning-view" });
    },
    refreshPlanning() {
      this.setPlanningParams(this.filterDateRange);
    },
    goTodayPlanning() {
      const scheduler = this.$refs.scheduler.instance;
      scheduler.scrollToDate(new Date());
    },
    handleColumnUpdate(selectedColumns) {
      this.schedulerConfig.columns.forEach((column) => {
        column.hidden = !selectedColumns.includes(column.field);
      });
    },
    editPlanning() {
      console.log("Edit planningCurrent planning view:", this.planningView);
      console.log("Edit planning Scheduler Key:", this.planningView.id + "_" + this.key);
      const planningViewData = this.planningView;
      this.$refs.modalEditPlanningView.setPlanningViewData(planningViewData);
      this.$refs.modalEditPlanningView.isVisible = true;
    },
    openEditor({ source, resourceRecord, eventRecord }) {
      Object.assign(this, {
        eventStore: source.eventStore,
        resourceId: resourceRecord.id,
        resourceTypeLine: resourceRecord.typeLine,
        eventRecord: eventRecord,
        showEditor: true,
      });
      this.$bvModal.show("modal-edit-event");
    },
    onCloseEditor() {
      const scheduler = this.$refs.scheduler.instance;
      if (scheduler) {
        scheduler.refresh();
      }
    },
    eventRenderer({ eventRecord, resourceRecord, renderData }) {
      let appendTitle = "";
      let title = "";
      let subTitle = "";
      let eventDetails = [];
      let shortLabels = "";
      let uniteHebdo = "jour";
      let eventHtml = "";

      if (resourceRecord.typeLine == 1) {
        // On créé des vignette pour les collab
        if (
          eventRecord.data.CollaboratorIds &&
          eventRecord.data.CollaboratorIds.length > 0
        ) {
          eventHtml += '<div class="bryntumEvent" style="flex-direction:row">';
          for (let i = 0; i < eventRecord.data.CollaboratorIds.length; i++) {
            const collaboratorId = eventRecord.data.CollaboratorIds[i];
            let collaborator = this.collaboratorsList.find(
              (collaborator) => collaborator.id == collaboratorId
            );
            if (i > 0) {
              eventHtml +=
                '<div class="bryntumEventAvatar" style="margin-left:-.5rem; ' +
                eventRecord.data.Cls.replace("background-", "") +
                ";" +
                eventRecord.data.Cls.replace("background-", "border-") +
                '">' +
                collaborator.firstName.charAt(0) +
                collaborator.lastName.charAt(0) +
                "</div>";
            } else {
              eventHtml +=
                '<div class="bryntumEventAvatar" style="' +
                eventRecord.data.Cls.replace("background-", "") +
                ";" +
                eventRecord.data.Cls.replace("background-", "border-") +
                '">' +
                collaborator.firstName.charAt(0) +
                collaborator.lastName.charAt(0) +
                "</div>";
            }
          }
          eventHtml += "</div>";
        }
        title = "Mission";
        if (eventRecord.MissionId) {
          title = this.missionsList.find(
            (elem) => elem.id == eventRecord.MissionId
          ).label;
        }
        if (
          eventRecord.EventTypeId &&
          eventRecord.EventTypeId > 0 &&
          this.eventTypesList.find((elem) => elem.id == eventRecord.EventTypeId)
        ) {
          if (
            eventRecord.name !==
            this.eventTypesList.find(
              (elem) => elem.id == eventRecord.EventTypeId
            ).label
          ) {
            subTitle = this.eventTypesList.find(
              (elem) => elem.id == eventRecord.EventTypeId
            ).label;
          }
          if (
            this.eventTypesList
              .find((elem) => elem.id == eventRecord.EventTypeId)
              .label.includes("nuit")
          ) {
            uniteHebdo = "nuit";
          }
        }
        if (eventRecord.HoursDay && eventRecord.HoursDay > 0) {
          if (subTitle == "") {
            subTitle = eventRecord.HoursDay + "h/" + uniteHebdo;
          } else {
            subTitle += " / " + eventRecord.HoursDay + "h/" + uniteHebdo;
          }
        }

        eventHtml +=
          '<div class="bryntumEvent"><div class="bryntumEventTitle"> ' +
          title +
          ' </div><div class="bryntumEventSubTitle"> ' +
          subTitle +
          " </div></div>";

        return StringHelper.xss`` + eventHtml + ``;
      } else {
        if (
          eventRecord.IsHidden &&
          eventRecord.UpdatedBy !== this.connectedUser.id
        ) {
          eventHtml =
            '<div class="bryntumEventTitle d-flex">' +
            appendTitle +
            '<div class="material-icons-outlined" style="font-size:14px">visibility_off</div></div>';
        } else {
          // L'évènement est masqué pour les autres utilisateurs
          if (eventRecord.IsHidden) {
            appendTitle +=
              '<div class="material-icons-outlined" style="font-size:11px">visibility_off</div>';
          }
          if (eventRecord.IsLocked) {
            appendTitle +=
              '<div class="material-icons-outlined" style="font-size:11px">lock</div>';
          }
          if (
            eventRecord.CompanyId &&
            !eventRecord.CompanyId &&
            this.companiesList.find((elem) => elem.id == eventRecord.CompanyId)
          ) {
            title +=
              " / " +
              this.companiesList.find(
                (elem) => elem.id == eventRecord.CompanyId
              ).name;
          }
          if (
            eventRecord.EventTypeId &&
            eventRecord.EventTypeId > 0 &&
            this.eventTypesList.find(
              (elem) => elem.id == eventRecord.EventTypeId
            )
          ) {
            if (
              eventRecord.name !==
              this.eventTypesList.find(
                (elem) => elem.id == eventRecord.EventTypeId
              ).label
            ) {
              subTitle = this.eventTypesList.find(
                (elem) => elem.id == eventRecord.EventTypeId
              ).label;
            }
            if (
              this.eventTypesList
                .find((elem) => elem.id == eventRecord.EventTypeId)
                .label.includes("nuit")
            ) {
              uniteHebdo = "nuit";
            }
          }
          if (eventRecord.HoursDay && eventRecord.HoursDay > 0) {
            if (subTitle == "") {
              subTitle = eventRecord.HoursDay + "h/" + uniteHebdo;
            } else {
              subTitle += " / " + eventRecord.HoursDay + "h/" + uniteHebdo;
            }
          }
          if (eventRecord.MissionId) {
            subTitle +=
              " / " +
              this.missionsList.find((elem) => elem.id == eventRecord.MissionId)
                .label;
          }

          if (
            eventRecord.eventDetails &&
            this.moduleParametersList.find(
              (el) => el.key == "showSlotsEventRenderer"
            ) &&
            this.moduleParametersList.find(
              (el) => el.key == "showSlotsEventRenderer"
            ).value == "1"
          ) {
            eventDetails = eventRecord.eventDetails.reduce((acc, obj) => {
              // On crée un object par date
              let findIndex = acc.findIndex(
                (el) => el.Date == dayjs(obj.Date).format("YYYY-MM-DD")
              );
              if (findIndex < 0) {
                // On instancie
                acc.push({
                  ...obj,
                  Date: dayjs(obj.Date).format("YYYY-MM-DD"),
                  Label:
                    obj.Duration > 0 ? obj.Duration + obj.ShortLabel : "&nbsp;",
                });
              } else {
                // On additionne
                if (obj.Duration > 0)
                  acc[findIndex]["Label"] += obj.Duration + obj.ShortLabel;
              }
              return acc;
            }, []);
            if (eventDetails.filter((el) => el.Label !== "")) {
              eventDetails.forEach((el) => {
                shortLabels += "<div>" + el.Label + "</div>";
              });
            }
          }
          eventHtml =
            '<div class="bryntumEventTitle">' +
            appendTitle +
            eventRecord.name +
            title +
            "</div>";
          if (shortLabels) {
            eventHtml +=
              '<div class="bryntumEventShortLabels">' + shortLabels + "</div>";
          } else {
            eventHtml +=
              '<div class="bryntumEventSubTitle">' + subTitle + "</div>";
          }
        }

        return (
          StringHelper.xss`<div class="bryntumEvent">` + eventHtml + `</div>`
        );
      }
    },
    tagging: function (e) {
      e.setClass("bg_" + e.itemData.color.substring(1));
    },
  },
};
</script>
<style lang="scss" scoped>
// on import app.scss
@import "~@bryntum/schedulerpro/schedulerpro.stockholm.css";

.show-filters-mobile {
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  background-color: #0c3571;
  color: #fff;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

@media screen and (max-width: 1335px) {
  .header-wrapper {
    flex-wrap: wrap;
    & > * {
      margin-bottom: 1rem !important;
    }
    & > *:not(h2) {
      width: 255px;
    }
  }
}

@media screen and (max-width: 768px) {
  .header-wrapper {
    max-height: 55px;
    transition: all 0.3s ease-in-out;
    overflow: hidden;
    & > * {
      width: 100% !important;
      justify-content: space-between;
      margin-right: 0 !important;
      border: none !important;
    }
  }
  .show-filters-mobile {
    display: block !important;
  }
}
</style>

<style>
.info {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.progress-bar-container {
  width: 100%;
  background-color: #e0e0e0;
  border-radius: 7px;
  overflow: hidden;
  position: relative;
  height: 14px;
}

.progress-bar {
  height: 100%;
  background-color: #4caf51;
  transition: width 0.3s;
}

.progress-label {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 0.8rem;
  line-height: 20px;
}

.progress-text {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  left: 0;
  color: black;
  line-height: 20px; /* Ajustez la hauteur selon vos besoins */
  font-size: 12px; /* Ajustez la taille de la police selon vos besoins */
  margin-top: 20px; /* Ajustez la marge selon vos besoins */
}

.over-limit .progress-bar {
  background-color: red;
}

.negative-value {
  background-color: red;
  color: white;
}

.page-container {
  height: 100%;
  overflow: hidden;
  width: 100%;
  transition: all 0.3s ease-in-out;
}
.e-btn-group.group-center .e-icon-btn span {
  margin-top: -4px;
}
</style>
