<template lang="pug">
.h-100.d-flex.flex-column.w-100
    HeaderGrid(:title="title" :uid="uid" :name="name" userFilter="affairCostingFilters" :dateRangeDefault="dateRangeDefault" :dateRanger="dateRanger" :tvaModeButton="tvaModeButton" :addButton="addButton" :addButton2="addButton2" :searchButton="searchButton" :tagguerButton="tagguerButton" :selectedRows="selectedRows" @setDates="setDates" @onFilterTextBoxChanged="onFilterTextBoxChanged" @selectParamerterAction="setSelectParamerterAction" @clickAction="clickAction" :searchPlaceholder="searchPlaceholder" @clickActionGroup="clickActionGroup" :indexSearch="indexSearch")
    div.card-list-wrapper.w-100
      div.w-100.d-flex.px-1
        div.cursor-pointer.col-card-list-stats.w-100(@click="changeTab(2)")
          .card-list-stats.border-warning.w-100(style="box-shadow: none !important; max-width: none !important;" :class="tabActive == '2' ? 'current' : ''")
              .card-list-stats-title
                  div
                      span.card-list-stats-title-badge.material-icons.text-warning.mr-50 ads_click
                      span.card-list-stats-title-text Prévu
                  .card-list-stats-title-badge.bg-warning.text-white {{ getSumCounts(2) }}
              .card-sum.d-flex
                  div
                      span.text-success +
                      span {{ formatCurrency(getSumCosts(2, 1)) }} 
                  span.mx-50 |
                  div
                      span.text-danger -
                      span {{ formatCurrency(getSumCosts(2, 2)) }} 
                  div &nbsp;
        div.cursor-pointer.pl-1.col-card-list-stats.w-100(@click="changeTab(1)")
          .card-list-stats.border-primary.w-100(style="box-shadow: none !important; max-width: none !important;" :class="tabActive == '1' ? 'current' : ''")
              .card-list-stats-title
                  div
                      span.card-list-stats-title-badge.material-icons.text-primary.mr-50 done_outline
                      span.card-list-stats-title-text Réél
                  .card-list-stats-title-badge.bg-primary.text-white {{ getSumCounts(1) }}
              .card-sum.d-flex
                  div
                      span.text-success +
                      span {{ formatCurrency(getSumCosts(1, 1)) }} 
                  span.mx-50 |
                  div
                      span.text-danger -
                      span {{ formatCurrency(getSumCosts(1, 2)) }} 
                  div &nbsp;
        div.cursor-pointer.pl-1.col-card-list-stats.w-100
          .card-list-stats.no-hover.w-100(:class="getMarge(2)>0 ? 'border-success' : 'border-danger'" style="box-shadow: none !important; max-width: none !important;")
              .card-list-stats-title
                  div
                      span.card-list-stats-title-badge.material-icons.mr-25(:class="getMarge(2)>0 ? 'text-success' : 'text-danger'") euro
                      span.card-list-stats-title-text Marge prévue
                  .card-list-stats-title-badge.text-white(:class="getMarge(2)>0 ? 'bg-success' : 'bg-danger'") {{ getPercentMarge(2) }}
              .card-sum(:class="getMarge(2)>0 ? 'text-success' : 'text-danger'")
                  | {{ formatCurrency(getMarge(2)) }}
                  span 
        div.cursor-pointer.pl-1.col-card-list-stats.w-100
          .card-list-stats.no-hover.w-100(:class="getMarge(1)>0 ? 'border-success' : 'border-danger'" style="box-shadow: none !important; max-width: none !important;")
              .card-list-stats-title
                  div
                      span.card-list-stats-title-badge.material-icons.mr-25(:class="getMarge(1)>0 ? 'text-success' : 'text-danger'") euro
                      span.card-list-stats-title-text Marge réélle
                  .card-list-stats-title-badge.text-white(:class="getMarge(1)>0 ? 'bg-success' : 'bg-danger'")  {{ getPercentMarge(1) }}
              .card-sum(:class="getMarge(1)>0 ? 'text-success' : 'text-danger'")
                  | {{ formatCurrency(getMarge(1)) }} 
                  span 
    .d-flex.w-100.h-100(style="overflow: hidden;")
        .text-center.flex-center.w-100(v-if='isLoadingAffairCostingsListByAffair || !headerLoaded')
            .loading-bg-inner
                .loader
                    .outer
                    .middle
                    .inner
            .mt-5
                br
                br
                br
                | Chargement de la liste des budgets et prévisions...
        sync-grid#overviewgrid(ref="overviewgrid" v-else :uid="uid" :gridData="dataFiltered" :name="name" :headerData="headerData" :allowGrouping="allowGrouping" :allowKeyboard="allowKeyboard" :tagguerButton="tagguerButton" @actionBegin="actionBegin" :editSettings="editSettings" @deleteButtonClicked="deleteButtonClicked")
</template>

<script>
import { mapGetters, mapActions } from "vuex";

import { formatNumber } from "@/types/api-orisis/library/FormatOperations.ts";
import { formatCurrency } from "@/types/api-orisis/library/FormatOperations.ts";
import { setColumnsByObject } from "@/types/api-orisis/library/ObjectOperations.ts";

var dayjs = require("dayjs");
var quarterOfYear = require("dayjs/plugin/quarterOfYear");
var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone");
var advanced = require("dayjs/plugin/advancedFormat");
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.tz.setDefault("Europe/Paris");
dayjs.extend(advanced);
dayjs.extend(quarterOfYear);

import CellRendererEditAmount from "@/components/global/ag-grid/CellRendererEditAmount.vue";
import StatCards from "@/components/global/grid/StatCards.vue";
import SyncGrid from "@/components/global/grid/Grid.vue";
import HeaderGrid from "@/components/global/grid/HeaderGrid.vue";
import { TreeVirtual } from "@syncfusion/ej2-vue-treegrid";
import { documentApplicationType } from "@/types/api-orisis/enums/enums";

export default {
  components: {
    SyncGrid,
    HeaderGrid,
    StatCards,
    CellRendererEditAmount,
  },
  props: {
    affairId: {
      default: null,
      required: true,
    },
  },
  data() {
    return {
      indexSearch: 0,
      title: "Budget",
      name: "affairCosting",
      uid: "affairCostings",
      headerData: [],
      headerLoaded: false,
      tabActive: "all",
      selectedRows: [],
      dateRangeDefault: [
        new Date(
          dayjs().subtract(3, "month").startOf("month").format("YYYY-MM-DD")
        ).toISOString(),
        new Date(dayjs().endOf("month").format("YYYY-MM-DD")).toISOString(),
      ],

      dateRanger: true,
      tvaModeButton: false,
      addButton: true,
      addButton2: false,
      searchButton: true,
      tagguerButton: false,
      searchPlaceholder: "Rechercher une ligne de budget",
      allowKeyboard: false,
      allowGrouping: true,
      selectionSettings: {
        type: "Multiple",
        enableSimpleMultiRowSelection: false,
      },
      commands: [
        {
          type: "Delete",
          buttonOption: { cssClass: "e-flat", iconCss: "e-delete e-icons" },
        },
        {
          type: "Save",
          buttonOption: { cssClass: "e-flat", iconCss: "e-save e-icons" },
        },
        {
          type: "Cancel",
          buttonOption: {
            cssClass: "e-flat",
            iconCss: "e-cancel-icon e-icons",
          },
        },
      ],
      editSettings: {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: "Normal",
        newRowPosition: "Bottom",
        allowNextRowEdit: true,
        titleAdd: "Nouveau budget",
        titleEdit: "Modifier le budget",
        titleField: "label",
      },
    };
  },
  computed: {
    ...mapGetters([
      "affairCostingsListByAffair",
      "isLoadingAffairCostingsListByAffair",
      "analyticsSettingsList",
      "isLoadingAnalyticsSettingsList",
      "collaboratorsList",
      "affairsList",
      "missionsList",
      "workspaceSelected",
      // "currenciesList",
      "userParametersList",
    ]),
    missionsListFiltered() {
      return this.$store.getters["missionsList"].map((elem) => {
        return {
          ...elem,
          parentId: elem.parentId == 0 ? null : elem.parentId,
          hasChild: this.missionsList.filter(
            (child) => child.parentId == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    analyticsSettingsListFiltered() {
      return this.$store.getters["analyticsSettingsList"].map((elem) => {
        return {
          ...elem,
          displayLabel: elem.analyticCode + " - " + elem.label,
          parentAccountNumber:
            elem.parentAccountNumber == 0 ? null : elem.parentAccountNumber,
          hasChild: this.analyticsSettingsList.filter(
            (child) =>
              child.parentAccountNumber == elem.id && child.id !== elem.id
          )
            ? true
            : false,
        };
      });
    },
    dataOrigine() {
      return this.$store.getters["affairCostingsListByAffair"];
    },
    dataFiltered() {
      if (this.tabActive == "all") {
        return this.$store.getters["affairCostingsListByAffair"];
      } else {
        return this.$store.getters["affairCostingsListByAffair"].filter(
          (elem) => elem.sourceType === this.tabActive
        );
      }
    },
  },
  async created() {
    if (!this.affairsList || this.affairsList.length == 0) {
      await this.getAffairs({});
    }
    if (!this.missionsList || this.missionsList.length == 0) {
      await this.getMissions({});
    }
    // if (!this.currenciesList || this.currenciesList.length == 0) {
    //   await this.getCurrencies({});
    // }
    if (!this.analyticsSettingsList || this.analyticsSettingsList.length == 0) {
      await this.getAnalyticsSettings({});
    }
    this.getCollaborators({});
    let filter = this.userParametersList.find(
      (el) => el.key == "affairCostingFilters"
    );
    if (filter) {
      if (JSON.parse(filter.value).dateRange) {
        this.setDates(JSON.parse(filter.value).dateRange);
      } else {
        this.setDates({
          startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
          endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
        });
      }
    } else {
      await this.setDates({
        startDate: dayjs().startOf("year").format("YYYY-MM-DD"),
        endDate: dayjs().endOf("year").format("YYYY-MM-DD"),
      });
    }
  },
  methods: {
    ...mapActions([
      "getAnalyticsSettings",
      "getAffairCostingsByAffairId",
      "getCollaborators",
      "getAffairs",
      "getMissions",
      // "getCurrencies",
      "createAffairCosting",
      "updateAffairCosting",
      "deleteAffairCostings",
    ]),
    formatNumber,
    formatCurrency,
    setColumnsByObject,
    setHeaderData() {
      this.headerData = [
        {
          type: "string",
          field: "date",
          headerText: "Mois",
          width: 140,
          maxWidth: 140,
          // format: "Md",
          // format: "formatDateOptions",
          template: "monthTemplate",
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          defaultValue: new Date(),
          showInColumnChooser: false,
          visible: true,
          edit: { type: "monthpicker" },
        },
        {
          type: "string",
          field: "sourceType",
          headerText: "Origine",
          filterTemplate: "sourceTypeFilterTemplate",
          textAlign: "Center",
          width: 100,
          minWidth: 100,
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          template: "sourceTypeTemplate",
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 2,
          edit: {
            name: "sourceType",
            source: [
              { label: "Réalisé", id: 1 },
              { label: "Prévu", id: 2 },
            ],
            fields: { text: "label", value: "id" },
            type: "dropdown", //<<FIX
          },
        },
        {
          type: "string",
          field: "directionType",
          filterTemplate: "directionTypeFilterTemplate",
          headerText: "Type",
          width: 90,
          minWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: false,
          visible: true,
          template: "directionTypeTemplate",
          // editType: "dropdownedit",
          valueAccessor: "dropdown",
          textAlign: "Center",
          defaultValue: 1,
          edit: {
            source: [
              { label: "Entrée", id: 1 },
              { label: "Sortie", id: 2 },
            ],
            fields: { text: "label", value: "id" },
            name: "directionType",
            type: "dropdowndependencies", //<<FIX
            target: "documentApplicationType",
          },
        },
        {
          type: "string",
          field: "documentApplicationType",
          filterTemplate: "documentApplicationTypeFilterTemplate",
          headerText: "Source",
          width: 90,
          minWidth: 90,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: true,
          visible: true,
          valueAccessor: "dropdown",
          foreignKeyValue: "id",
          edit: {
            source: documentApplicationType,
            fields: {
              text: "label",
              value: "id",
            },
            name: "documentApplicationType",
            type: "dropdown", //<<FIX
          },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "analyticsSettingId",
          headerText: "Code analytique lié",
          width: 200,
          minWidth: 200,
          allowFiltering: true,
          allowSorting: true,
          allowEditing: true,
          showInColumnChooser: false,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.analyticsSettingsListFiltered,
            fields: {
              text: "displayLabel",
              value: "id",
              parentValue: "parentAccountNumber",
              hasChildren: "hasChild",
              expanded: "hasChild",
              dataSource: this.analyticsSettingsListFiltered,
            },
            type: "dropdowntree",
            name: "dropdowntreeanalytics",
          },
          validationRules: { required: true },
        },
        {
          type: "string",
          field: "label",
          headerText: "Libellé",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          allowGrouping: false,
          visible: true,
          edit: { type: "textbox", name: "textboxlabel" },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "collaboratorId",
          headerText: "Collaborateur",
          allowFiltering: true,
          allowSorting: false,
          allowEditing: true,
          showInColumnChooser: false,
          visible: true,
          valueAccessor: "dropdown",
          edit: {
            source: this.collaboratorsList,
            fields: { text: "displayLabel", value: "id" },
            type: "dropdown",
            name: "affairCostingDropdownCollaboratorsList",
          },
          filterTemplate: "collaboratorFilterTemplate",
        },
        {
          type: "number",
          format: "formatNumber",
          field: "quantity",
          allowAggregate: true,
          headerText: "Qté réelle",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          format: "formatNumber",
          field: "valuatedQuantity",
          headerText: "Qté valorisée",
          width: 140,
          maxWidth: 140,
          textAlign: "center",
          allowEditing: true,
          allowFiltering: true,
          allowAggregate: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          showFooterSum: true,
          defaultValue: 1,
          editType: "numericedit",
          edit: { params: { decimals: 2 } },
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountUnit",
          headerText: "Mt Unitaire",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          showFooterSum: true,
          defaultValue: 0,
          editType: "numericedit",
          edit: { params: { decimals: 2, step: 0.05 } },
          format: "formatCurrency",
          validationRules: { required: true },
        },
        {
          type: "number",
          field: "amountAffairCosting",
          headerText: "Mt Total",
          width: 140,
          maxWidth: 140,
          textAlign: "right",
          allowEditing: true,
          allowFiltering: true,
          allowAggregate: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: true,
          showFooterSum: true,
          format: "formatCurrency",
          editTemplate: () => {
            return {
              template: CellRendererEditAmount,
            };
          },
          valueAccessor: "sumAffairCostingCurrencyFormatter",
          // validationRules: { required: true },
        },
        // {
        //   type: "string",
        //   field: "currencyId",
        //   headerText: "Devise",
        //   allowFiltering: false,
        //   allowSorting: false,
        //   allowEditing: true,
        //   width: 100,
        //   showInColumnChooser: false,
        //   visible: true,
        //   valueAccessor: "dropdown",
        //   edit: {
        //     source: this.currenciesList,
        //     fields: { text: "symbol", value: "id" },
        //     type: "dropdown",
        //   },
        // },
        {
          type: "string",
          field: "documentApplicationType",
          headerText: "Type Document lié",
          width: 140,
          allowEditing: false,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: false,
          visible: false,
        },
        {
          type: "string",
          field: "documentPath",
          headerText: "URL Document lié",
          width: 140,
          allowEditing: false,
          allowFiltering: true,
          allowSorting: true,
          showInColumnChooser: true,
          visible: false,
        },
      ];
    },

    async setDates(dates) {
      if (dates.startDate) {
        let dateRange = {
          startDate: new Date(dates.startDate),
          endDate: dates.endDate ? new Date(dates.endDate) : new Date(),
        };
        this.dateRangeDefault = [dateRange.startDate, dateRange.endDate];
        await this.getAffairCostingsByAffairId({
          startDate: dateRange.startDate,
          endDate: dateRange.endDate,
          affairId: this.affairId,
          sourceType: null,
        }).then(async (res) => {
          this.setHeaderData();
          if (res.length) {
            await this.setColumnsByObject(res[0], null, this);
            this.headerData.push({
              type: "commands",
              commands: this.commands,
              headerText: "Actions",
              freeze: "right",
              width: 210,
            });
            this.indexSearch++;
          }
          this.headerLoaded = true;
        });
      }
    },
    getSumCounts(sourceType, directionType) {
      let sum = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          sum += obj["valuatedQuantity"];
        });
      return sum.toFixed(0);
    },
    getSumCosts(sourceType, directionType) {
      let sum = 0;
      this.dataOrigine
        .filter(
          (elem) =>
            elem.sourceType == sourceType && elem.directionType == directionType
        )
        .forEach((obj) => {
          sum += obj["amountUnit"] * obj["valuatedQuantity"];
        });
      return sum;
    },
    getPercentMarge(sourceType) {
      let sumInput = 0;
      let sumOutput = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          if (obj.directionType == 1) {
            sumInput += obj["amountUnit"] * obj["valuatedQuantity"];
          } else if (obj.directionType == 2) {
            sumOutput += obj["amountUnit"] * obj["valuatedQuantity"];
          }
        });
      if (sumInput === 0) {
        return "0 %";
      } else {
        let marge = ((sumInput - sumOutput) / sumInput) * 100;
        return this.formatNumber(marge) + " %";
      }
    },
    getMarge(sourceType) {
      let sum = 0;
      this.dataOrigine
        .filter((elem) => elem.sourceType == sourceType)
        .forEach((obj) => {
          if (obj.directionType == 1) {
            sum += obj["amountUnit"] * obj["valuatedQuantity"];
          } else {
            sum -= obj["amountUnit"] * obj["valuatedQuantity"];
          }
        });
      return sum;
    },
    async changeTab(tab) {
      if (this.tabActive == tab) {
        this.tabActive = "all";
      } else {
        this.tabActive = tab;
      }
    },
    async actionBegin(args) {
      if (args.requestType === "delete") {
        this.deleteButtonClicked(args);
      }
      if (args.requestType === "save" && args.action === "add") {
        await this.createAffairCosting({
          affairCosting: {
            ...args.data,
            id: 0,
            affairId: this.affairId,
            missionId: args.data.missionId,
            analyticsSettingId: parseInt(args.data.analyticsSettingId[0]),
          },
          route: "affair",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.refresh();
      }
      if (args.requestType === "save" && args.action === "edit") {
        this.updateAffairCosting({
          affairCosting: {
            ...args.data,
            affairId: this.affairId,
            missionId: args.data.missionId,
            analyticsSettingId: parseInt(args.data.analyticsSettingId[0]),
          },
          route: "affairCosting",
        });
      }
    },
    async deleteButtonClicked(args) {
      if (args.rowData && args.rowData.affairId) {
        await this.deleteAffairCostings({
          affairCostingIds: [args.rowData.id],
          route: "affairCosting",
        });
      }
      this.$refs.overviewgrid.$refs.overviewgrid.closeEdit();
      this.$refs.overviewgrid.$refs.overviewgrid.refresh();
    },
    onFilterTextBoxChanged(search) {
      this.$refs.overviewgrid.onFilterTextBoxChanged(search);
    },
    clickAction() {
      this.$refs.overviewgrid.$refs.overviewgrid.addRecord();
    },
    clickActionGroup(val) {
      this.selectedRows.forEach((row) => {
        let dataUpdate = {
          ...row,
          status: val,
          collaboratorId: row.collaborator.id,
          payrollEventId: row.payrollEvent.id,
        };
        this.updateAffairCosting({
          affairCosting: dataUpdate,
          route: "affairCosting",
        });
        this.$refs.overviewgrid.$refs.overviewgrid.setRowData(
          dataUpdate.id,
          dataUpdate
        );
      });
    },
    setSelectParamerterAction(args) {
      if (["csv", "xls", "print"].includes(args.item.type)) {
        this.$refs.overviewgrid.exportData(args.item.type);
      } else {
        this.$refs.overviewgrid.$refs.overviewgrid.openColumnChooser();
      }
    },
  },
};
</script>
