<template lang="pug">
.d-flex.w-100.align-items-end
  span.w-100 {{ item }}
</template>

<script>
export default {
  data() {
    return {
      item: null,
      data: null,
    };
  },
  beforeMount() {
    this.item =
      (this.data.amountUnit * this.data.valuatedQuantity).toFixed(2) + " €";
  },
};
</script>
